import React, { useEffect } from "react";
import { Autocomplete, TextField, Container } from "@mui/material";

const Acr = ({ gradepara, setPersonalData, personalData }) => {

  if (!gradepara) {
    return <div>Loading...</div>; // Show a loading state if gradepara is not available
  }

  

  return (
    <div className="p-6 mt-8">
      <h1 className="text-2xl text-center w-full font-bold flex items-center mb-4 text-gray-800">
        Last Five Years Annual Confidential Report (ACR)
      </h1>

      {/* Render containers for each year (5 to 1) */}
      <Container maxWidth={"xs"} className="mt-4">
        {gradepara.acr_5 && gradepara.acr_5.length > 0 && (
          <Autocomplete
            options={gradepara.acr_5.map((item) => ({
              label: item.name,
              value: item.id,
            }))}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Year 5"
                name="gradeYear5"
                variant="outlined"
                color="success"
                type="text"
                required
                fullWidth
              />
            )}
            onInputChange={(event, newValue) => {
              const selectedOption = gradepara.acr_5.find(
                (item) => item.name === newValue
              );
              if (selectedOption) {
                const newId = selectedOption.id;
                setPersonalData((prevData) => {
                  const updatedGrade = Array.isArray(prevData.grade)
                    ? [...prevData.grade]
                    : Array(5).fill(null);
                  updatedGrade[4] = newId; // Update the 5th index (4)
                  return {
                    ...prevData,
                    grade: updatedGrade,
                  };
                });
              }
            }}
            value={gradepara.acr_5.find(
              (item) => item.id === personalData.grade?.[4]
            )}
            getOptionLabel={(option) => option?.label || ""}
            isOptionEqualToValue={(option, value) => option.value === value?.id}
          />
        )}
      </Container>

      <Container maxWidth={"xs"} className="mt-4">
        {gradepara.acr_4 && gradepara.acr_4.length > 0 && (
          <Autocomplete
            options={gradepara.acr_4.map((item) => ({
              label: item.name,
              value: item.id,
            }))}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Year 4"
                name="gradeYear4"
                variant="outlined"
                color="success"
                type="text"
                required
                fullWidth
              />
            )}
            onInputChange={(event, newValue) => {
              const selectedOption = gradepara.acr_4.find(
                (item) => item.name === newValue
              );
              if (selectedOption) {
                const newId = selectedOption.id;
                setPersonalData((prevData) => {
                  const updatedGrade = [...(prevData.grade || [])];
                  updatedGrade[5] = newId; // Update the 4th index (3)
                  return {
                    ...prevData,
                    grade: updatedGrade,
                  };
                });
              }
            }}
            value={gradepara.acr_4.find(
              (item) => item.id === personalData.grade?.[3]
            )}
            getOptionLabel={(option) => option?.label || ""}
            isOptionEqualToValue={(option, value) => option.value === value?.id}
          />
        )}
      </Container>

      <Container maxWidth={"xs"} className="mt-4">
        {gradepara.acr_3 && gradepara.acr_3.length > 0 && (
          <Autocomplete
            options={gradepara.acr_3.map((item) => ({
              label: item.name,
              value: item.id,
            }))}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Year 3"
                name="gradeYear3"
                variant="outlined"
                color="success"
                type="text"
                required
                fullWidth
              />
            )}
            onInputChange={(event, newValue) => {
              const selectedOption = gradepara.acr_3.find(
                (item) => item.name === newValue
              );
              if (selectedOption) {
                const newId = selectedOption.id;
                setPersonalData((prevData) => {
                  const updatedGrade = [...(prevData.grade || [])];
                  updatedGrade[6] = newId; // Update the 3rd index (2)
                  return {
                    ...prevData,
                    grade: updatedGrade,
                  };
                });
              }
            }}
            value={gradepara.acr_3.find(
              (item) => item.id === personalData.grade?.[2]
            )}
            getOptionLabel={(option) => option?.label || ""}
            isOptionEqualToValue={(option, value) => option.value === value?.id}
          />
        )}
      </Container>

      <Container maxWidth={"xs"} className="mt-4">
        {gradepara.acr_2 && gradepara.acr_2.length > 0 && (
          <Autocomplete
            options={gradepara.acr_2.map((item) => ({
              label: item.name,
              value: item.id,
            }))}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Year 2"
                name="gradeYear2"
                variant="outlined"
                color="success"
                type="text"
                required
                fullWidth
              />
            )}
            onInputChange={(event, newValue) => {
              const selectedOption = gradepara.acr_2.find(
                (item) => item.name === newValue
              );
              if (selectedOption) {
                const newId = selectedOption.id;
                setPersonalData((prevData) => {
                  const updatedGrade = [...(prevData.grade || [])];
                  updatedGrade[7] = newId; // Update the 2nd index (1)
                  return {
                    ...prevData,
                    grade: updatedGrade,
                  };
                });
              }
            }}
            value={gradepara.acr_2.find(
              (item) => item.id === personalData.grade?.[1]
            )}
            getOptionLabel={(option) => option?.label || ""}
            isOptionEqualToValue={(option, value) => option.value === value?.id}
          />
        )}
      </Container>

      <Container maxWidth={"xs"} className="mt-4">
        {gradepara.acr_1 && gradepara.acr_1.length > 0 && (
          <Autocomplete
            options={gradepara.acr_1.map((item) => ({
              label: item.name,
              value: item.id,
            }))}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Year 1"
                name="gradeYear1"
                variant="outlined"
                color="success"
                type="text"
                required
                fullWidth
              />
            )}
            onInputChange={(event, newValue) => {
              const selectedOption = gradepara.acr_1.find(
                (item) => item.name === newValue
              );
              if (selectedOption) {
                const newId = selectedOption.id;
                setPersonalData((prevData) => {
                  const updatedGrade = [...(prevData.grade || [])];
                  updatedGrade[8] = newId; // Update the 1st index (0)
                  return {
                    ...prevData,
                    grade: updatedGrade,
                  };
                });
              }
            }}
            value={gradepara.acr_1.find(
              (item) => item.id === personalData.grade?.[0]
            )}
            getOptionLabel={(option) => option?.label || ""}
            isOptionEqualToValue={(option, value) => option.value === value?.id}
          />
        )}
      </Container>
    </div>
  );
};

export default Acr;
