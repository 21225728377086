import React, { useState } from 'react';
import {
  Box,
  Container,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const Aboutus = () => {
  const [open, setOpen] = useState(false);
  const [selectedPdf, setSelectedPdf] = useState("");

  const handleOpen = (pdfFile) => {
    setSelectedPdf(pdfFile);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedPdf("");
  };

  return (
    <Box style={{ backgroundColor: '#f5f5f5', padding: '40px 0' }}>
      <Container>
        <Box textAlign="center" mb={5}>
          <Typography variant="h3" style={{ fontWeight: 'bold', color: '#2E7D32' }}>
            About Us
          </Typography>
        </Box>
        <div className='flex justify-center items-center'>
          
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleOpen("1.pdf")}
            style={{ marginRight: '10px' }}
            className='flex-1'
          >
            <Typography paragraph>
           चयन प्रक्रिया अपनाने के सम्बंद में
           </Typography>
          </Button>
          <Button className='flex-1' variant="contained" color="primary" onClick={() => handleOpen("2.pdf")}>
          <Typography paragraph>
            सेवानिवृत्त सेवकों को संविदा आधार पर नियुक्त किये जाने के संबंध में
          </Typography>
          </Button>
        </div>


        <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
          <div className='mt-4'>
          <DialogTitle className='mt-16'>
            About us 
            <IconButton
              aria-label="close"
              onClick={handleClose}
              style={{ position: 'absolute', right: 8, top: 8 }}
            >
              <CloseIcon className='mt-8' />
            </IconButton>
          </DialogTitle>
          </div>
          <DialogContent dividers>
            {selectedPdf && (
              <iframe
                src={`/${selectedPdf}`}
                width="100%"
                height="600px"
                title="PDF Viewer"
                style={{ border: 'none' }}
              ></iframe>
            )}
          </DialogContent>
        </Dialog>
      </Container>
    </Box>
  );
};

export default Aboutus;
