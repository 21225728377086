import axios from "axios";
import Swal from "sweetalert2";

//const BASE_URL = 'https://localhost:7186/api';
//'https://63a3-2409-4064-2587-69cc-2d13-b124-7f42-86f7.ngrok-free.app/api';
//const URL = 'https://localhost:7237/'
const URL = "https://scst.bprnetwork.in/";
const BASE_URL = URL + "api";
//const BASE_URL = 'https://satyam.bprnetwork.in/api';

const axiosWithToken = () => {
  const instance = axios.create({
    // baseURL: BASE_URL,

    headers: {
      Authorization: `Bearer ${
        JSON.parse(localStorage.getItem("user"))?.password
      }`,
      "Content-Type": "application/json",
      "ngrok-skip-browser-warning": "69420",
    },
  });
  return instance;
};

// Function to fetch data from an API endpoint
export const fetchData = async (endpoint) => {
  try {
    const response = await axiosWithToken().get(`${BASE_URL}/${endpoint}`);
    return response.data;
  } catch (error) {
    console.log(error);
    throw await errorresponse(error);
  }
};

// Function to post data to an API endpoint
export const postData = async (endpoint, data) => {
  try {
    const response = await axiosWithToken().post(
      `${BASE_URL}/${endpoint}`,
      data
    );
    return response.data;
  } catch (error) {
    throw await errorresponse(error);
  }
};

export const delteData = async (endpoint) => {
  try {
    const response = await axiosWithToken().get(`${BASE_URL}/${endpoint}`);
    return response.data;
  } catch (error) {
    throw await errorresponse(error);
  }
};
export const getBaseUrl = () => {
  //alert(`${URL}`)
  return `${URL}`;
};

const errorresponse = async (error) => {
  console.log(JSON.stringify(error));
  const reerror = {};
  if (error.response.data.status === 500) {
    reerror.title = error.response.data.title;
    reerror.details = error.response.data.detail;
  } else if (error.response.status === 400) {
    reerror.title = "Invalid Access";
    reerror.details = JSON.stringify(error.response.data);
  } else if (error.response.status === 404) {
    reerror.title = "Invalid Access";
    reerror.details = error.message + "(Invalid URL)";
  } else if (error.response.status === 401) {
    reerror.title = "Invalid Access";
    reerror.details = "Invalid Access, Please relogin";
  } else if (error.response.status === 403) {
    reerror.title = "Authorized";
    reerror.details = "You are not authorized to access this";
  }
  return reerror;
};

export const posterFunction = async (api, data) => {
  try {
    const res = await postData(api, data);

    Swal.fire({
      title: "Success",
      icon: "success",
      text: res,
    });
  } catch (e) {
    console.error(e);
    Swal.fire({
      title: "Error",
      icon: "error",
      text: e.details,
    });
  }
};

export const callerFunction = async (api) => {
  try {
    const res = await fetchData(api);
    return res;
  } catch (e) {
    console.error(e);
    Swal.fire({
      title: "Error",
      icon: "error",
      text: e.details,
    });
  }
};


const p = ' 0823B ';