import React from 'react';
import { Box, Container, Typography, Divider, Button, Grid } from '@mui/material';

const Tnc = () => {
  return (
    <Box style={{ backgroundColor: '#f9f9f9', padding: '40px 0' }}>
      <Container>
        <Box textAlign="center" mb={5}>
          <Typography variant="h3" style={{ fontWeight: 'bold', color: '#333' }}>
            Terms & Conditions
          </Typography>
          <Divider style={{ width: '60px', margin: '10px auto', backgroundColor: '#629584' }} />
        </Box>
        
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Typography variant="h5" gutterBottom style={{ fontWeight: 'bold' }}>
              1. Acceptance of Terms
            </Typography>
            <Typography variant="body1" paragraph>
              By accessing this website, you agree to be bound by these Terms and Conditions. If you do not agree to these terms, please do not use this site.
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="h5" gutterBottom style={{ fontWeight: 'bold' }}>
              2. Use of Content
            </Typography>
            <Typography variant="body1" paragraph>
              All content on this website, including text, graphics, logos, and images, is the property of the SC & ST Welfare Department of Bihar. Unauthorized use of any content is strictly prohibited.
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="h5" gutterBottom style={{ fontWeight: 'bold' }}>
              3. Privacy Policy
            </Typography>
            <Typography variant="body1" paragraph>
              We are committed to protecting your privacy. Our Privacy Policy outlines how we collect, use, and safeguard your information. By using this website, you agree to the terms of our Privacy Policy.
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="h5" gutterBottom style={{ fontWeight: 'bold' }}>
              4. User Responsibilities
            </Typography>
            <Typography variant="body1" paragraph>
              Users are responsible for ensuring that their use of the website does not violate any applicable laws. Any unlawful activities may result in immediate termination of access to the site.
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="h5" gutterBottom style={{ fontWeight: 'bold' }}>
              5. Limitation of Liability
            </Typography>
            <Typography variant="body1" paragraph>
              The SC & ST Welfare Department of Bihar is not liable for any damages that may arise from the use or inability to use this website. This includes, but is not limited to, direct, indirect, incidental, or consequential damages.
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="h5" gutterBottom style={{ fontWeight: 'bold' }}>
              6. Changes to Terms
            </Typography>
            <Typography variant="body1" paragraph>
              We reserve the right to modify these Terms and Conditions at any time. Changes will be effective immediately upon posting. Continued use of the site following any changes indicates your acceptance of the new terms.
            </Typography>
          </Grid>
          
          <Grid item xs={12} textAlign="center" mt={3}>
            <Button variant="contained" color="primary" style={{ backgroundColor: '#629584' }}>
              Accept Terms & Conditions
            </Button>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default Tnc;
