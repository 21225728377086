import React, { useEffect, useState } from "react";
import { TextField, Button, Container, Autocomplete, CircularProgress } from "@mui/material";
import {
  getCategories,
  getDistrict,
  getSubjects,
} from "../../../Functon/function";
import { fetchData } from "../../../Api";
import SearchList from "./SearchList";
import Swal from "sweetalert2";

const MIS = () => {
  const [loader, setLoader] = useState(false);
  const [aDistrictId, setaDistrictId] = useState("");
  const [subject, setSubject] = useState("");
  const [categoryId, setCategoryId] = useState("");
  const [mobile, setMobile] = useState("");
  const [districts, setDistricts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [subjects, setSubjects] = useState([]);
  const [TeacherList, setTeacherList] = useState([]);
  const [selectedDist, setSelectedDist] = useState(null);
  const [fdate, setFDate] = useState(new Date());
  const [tdate, setTDate] = useState(new Date());
  useEffect(() => {
    handleMaster();
  }, []);

  const handleMaster = async () => {
    const dres = await getDistrict();
    const sres = await getSubjects();
    const cres = await getCategories();
    setDistricts(dres);
    setCategories(cres);
    setSubjects(sres);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoader(true);
      const data = await fetchData(
        `Action/searchapplicant?adistrict=${aDistrictId==='' ? 0 : aDistrictId}&subject=${
          subject ==='All'?'':subject
        }&category=${categoryId ==='All' ? null : categoryId}&mobile=${mobile}&fdate=${fdate}&tdate=${tdate}`
      );
      if(data.length===0)
      {
        Swal.fire({
          title : 'No Record Found',
          icon : 'warning',
          text : "We did't find any record according to selected parameters"
        });
      }
      setTeacherList(data);
    } catch (e) {
      Swal.fire({
        title : 'Error',
        icon : 'error',
        text : e.details
      })
      
    }
    finally{
      setLoader(false);

    }
  };

  const data = {
    selectedDist, subject, categoryId, mobile, fdate,tdate
  }

  

  return (
    <>
    {loader ? (
        <div className="flex justify-center items-center w-full h-screen">
          <CircularProgress size="4rem" /> Please Wait.....
        </div>
      ) : 
      <>

      <Container maxWidth="lg" className="pt-4">
        <h1 className="text-3xl font-bold text-center text-gray-800 mb-8">
          Applicant List
        </h1>
        <form
          onSubmit={handleSubmit}
          className=" flex md:flex-row flex-col justify-center items-center"
        >
          {/* District */}
                <div className="flex-1 w-full">
            <Autocomplete
              options={[{name : 'All', id : 0}, ...districts]}
              fullWidth
              getOptionLabel={(option) => option.name || ""}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="District"
                  variant="outlined"
                  color="success"
                />
              )}
              onChange={(event, newValue) => {
                setaDistrictId(newValue ? newValue.id : 0);
                setSelectedDist(newValue);
              }}
              value={districts.find((item) => item.id === aDistrictId) || {
                name: "All",
                id: null,
              }}
            />
          </div>

          {/* Subject */}
                <div className="flex-1 w-full">
            <Autocomplete
              options={[{ sname: "All", id: "" }, ...subjects]} // Add 'Blank' option
              getOptionLabel={(option) => option.sname || ""}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Subject Specialization"
                  variant="outlined"
                  color="success"
                />
              )}
              onChange={(event, newValue) => {
                setSubject(newValue?.sname || "");
              }}
              value={
                subjects.find((item) => item.sname === subject) || {
                  sname: "All",
                  id: null,
                }
              } // Show selected or 'Blank'
            />
          </div>

          {/* Category */}
                <div className="flex-1 w-full">
            <Autocomplete
            options={[{ cname: "All",id:""}, ...categories]} // Add 'Blank' option
            
              getOptionLabel={(option) => option.cname || ""}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Category"
                  variant="outlined"
                  color="success"
                />
              )}
              onChange={(event, newValue) => {
                setCategoryId(newValue?.cname==='All'? "" : newValue?.cname || "");
              }}
              //value={categories.find((c) => c.cname === categoryId) || null}
              value={
                categories.find((item) => item.sname === categoryId) || {
                  cname: "All",
                  id: null,
                }
              } // S
            />
          </div>

          {/* Mobile */}
                <div className="flex-1 w-full">
            <TextField
              label="Mobile"
              variant="outlined"
              fullWidth
              value={mobile}
              onChange={(e) => setMobile(e.target.value)}
              className="bg-white"
              type="tel"
              inputProps={{
                maxLength: 10,
                pattern: "[0-9]*",
              }}
            />
          </div>
{/* From Date */}
<div className="flex-1 w-full">
            <TextField
              label="FDate"
              variant="outlined"
              fullWidth
              value={fdate}
              onChange={(e) => setFDate(e.target.value)}
              className="bg-white"
              type="date"
             required
            />
          </div>
          {/* To Date */}
<div className="flex-1 w-full">
            <TextField
              label="TDate"
              variant="outlined"
              fullWidth
              value={tdate}
              onChange={(e) => setTDate(e.target.value)}
              className="bg-white"
              type="date"
              required
              
            />
          </div>
          {/* Submit Button */}
          <div className="text-center">
            <button
              type="submit"
              className="bg-blue-500 hover:bg-blue-600 text-white font-semibold py-4 px-8 rounded"
            >
              Search
            </button>
          </div>
        </form>
      </Container>
      {TeacherList?.length > 0 && 
      <SearchList 
      tutorsList={TeacherList} 
      searchPara={data}
      />}
      </>
}
    </>
  );
};

export default MIS;
