import { Button, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import { postData } from "../../Api";
import CircularProgress from "@mui/material/CircularProgress";
import Swal from "sweetalert2";

const Login = () => {
  const [loginMode, setLoginMode] = useState(true);
  const [loader, setLoader] = useState(false);
  const [loginData, setLoginData] = useState({
    username: "",
    password: "",
    newPassword: "",
  });
  const [regData, setRegData] = useState({
    name: "",
    mobile: "",
    email: "",
  });

  const handleChange = (e) => {
    if (loginMode) {
      setLoginData({ ...loginData, [e.target.name]: e.target.value });
    } else {
      setRegData({ ...regData, [e.target.name]: e.target.value });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent default form submission
    setLoader(true);

    // Validation check
    if (loginMode) {
      if (!loginData.username || !loginData.password) {
        alert("Please fill in all login fields.");
        setLoader(false);
        return;
      }
    } else {
      if (!regData.name || !regData.mobile || !regData.email) {
        alert("Please fill in all registration fields.");
        setLoader(false);
        return;
      }
    }

    try {
      if (loginMode) {
        const res = await postData("User/login", loginData);
        localStorage.setItem("user", JSON.stringify(res));
        window.location.reload();
      } else {
        const res = await postData("User/registration", regData);
        setLoginMode(true);
        setLoader(false);
        Swal.fire({
          title : 'Registered',
          icon : 'success',
          text : res
        })
      }
    } catch (e) {
      Swal.fire({
        title : 'Error',
        icon : 'error',
        text : e.details
      })
      setLoader(false);
      console.error(e);
    }
  };

  return (
    <>
      {loader ? (
        <div className="flex justify-center items-center w-full h-screen">
          <CircularProgress size="4rem" />
        </div>
      ) : 
      (
        <div className="p-4 md:grid  grid-cols-2 flex-col">
          <div className="flex justify-center items-center flex-col">
            <Typography variant="h5" marginTop={0} alignSelf='center' fontWeight={700}>
            अनुसूचित जाति एवं अनुसूचित जनजाति कल्याण विभाग द्वारा संचालित आवासीय विद्यालय में सेवानिर्वित शिक्षको को संविदा नियोजन करने हेतु	ऑनलाइन पोर्टल
            </Typography>
            <img
            src = "background.jpg"
            className="w-[100%] h-[80%] rounded-lg "
            />
          </div>
          <div className="md:ml-4 ml-0 w-full mt-12">
            <div className="flex flex-col w-full px-2  gap-4  justify-center items-center shadow-sm hover:shadow-md hover:shadow-black shadow-black md:h-[90vh] h-auto">
              <div className="bg-[#002b47] md:w-[60%] w-full px-8 text-white py-1 rounded-md">
                <Typography fontWeight={700} fontSize={20}>
                  {loginMode ? "Log In ( लॉगिन करें )" : "Register ( पंजीकरण )"}
                </Typography>
              </div>
              <form onSubmit={handleSubmit} className="w-full flex flex-col justify-center items-center"> {/* Wrap input fields in form */}
                {loginMode && (
                  <div className="md:w-[60%] mt-8 mb-4 w-full flex flex-col justify-center items-center gap-4">
                    <TextField
                      name="username"
                      type="text"
                      fullWidth
                      placeholder="username"
                      label="username"
                      value={loginData.username}
                      onChange={handleChange}
                      required
                    />
                    <TextField
                      name="password"
                      type="password"
                      placeholder="Password"
                      label="Password"
                      fullWidth
                      value={loginData.password}
                      onChange={handleChange}
                      required
                    />
                  </div>
                )}
                {!loginMode && (
                  <div className="md:w-[60%] mt-8 w-full flex flex-col justify-center items-center gap-4">
                    <TextField
                      name="name"
                      type="text"
                      fullWidth
                      placeholder="name"
                      label="Name"
                      value={regData.name}
                      onChange={handleChange}
                      required
                    />
                    <TextField
                      name="mobile"
                      type="number"
                      placeholder="Mobile"
                      label="Mobile"
                      fullWidth
                      value={regData.mobile}
                      onChange={handleChange}
                      inputProps={{ maxLength: 10 }}
                      onInput={(e) => {
                        if (e.target.value.length > 10) {
                          e.target.value = e.target.value.slice(0, 10);
                        }
                      }}
                      required
                    />
                    <TextField
                      name="email"
                      type="email"
                      placeholder="abc@gmail.com"
                      label="Email ID"
                      fullWidth
                      value={regData.email}
                      onChange={handleChange}
                      required
                    />
                  </div>
                )}
                <div>
                  <button
                    type="submit" // Set button type to submit
                    className="bg-[#002b47] text-white p-2 hover:shadow-md hover:shadow-black px-8 rounded-lg"
                  >
                    Submit
                  </button>
                </div>
              </form>
              {loginMode && (
                <div className="flex justify-end items-end w-full px-4 md:w-[70%]">
                  <Button className="">Forgot Password</Button>
                </div>
              )}
              <div className="bg-[#003e67] w-full md:w-[60%] mt-4 flex justify-center rounded-sm">
                <button
                  type="button" // This button should not submit the form
                  className="text-white p-2 rounded-md"
                  onClick={() => setLoginMode(!loginMode)}
                >
                  {loginMode ? "Register" : "Log In"}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Login;


