import { Typography } from "@mui/material";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { IoMdMenu } from "react-icons/io";

const NavBar = () => {
  const user = localStorage.getItem("user");
  const userId = JSON.parse(user)?.usertypeId;
  console.log("user", userId);
  const [showMenu, setShowMenu] = useState(false);
  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.removeItem("user");
    window.location.href = "/";
  };

  const renderUserLinks = () => {
    if (userId === 3) {
      return (
        <>
          <button
            onClick={() => navigate("/")}
            className="text-white hover:bg-slate-600 hover:transition-all hover:ease-in-out p-1 px-4 rounded-md"
          >
            Dashboard
          </button>
          
          <button
            onClick={() => navigate("/profile")}
            className="text-white hover:bg-slate-600 hover:transition-all hover:ease-in-out p-1 px-4 rounded-md"
          >
            Profile
          </button>
          <button
            onClick={() => navigate("/pan")}
            className="text-white hover:bg-slate-600 hover:transition-all hover:ease-in-out p-1 px-4 rounded-md"
          >
            Documents
          </button>
          {/* <button
            onClick={() => navigate("/education")}
            className="text-white hover:bg-slate-600 hover:transition-all hover:ease-in-out p-1 px-4 rounded-md"
          >
            Education
          </button> */}
          <button
            onClick={() => navigate("/experience")}
            className="text-white hover:bg-slate-600 hover:transition-all hover:ease-in-out p-1 px-4 rounded-md"
          >
            Experience
          </button>
          
        </>
      );
    }

    if (userId === 1 || userId === 2) {
      return (
        <>
          <button
            onClick={() => navigate("/")}
            className="text-white hover:bg-slate-600 hover:transition-all hover:ease-in-out p-1 px-4 rounded-md"
          >
            Dashboard
          </button>
          <button
            onClick={() => navigate("/admin/mis")}
            className="text-white hover:bg-slate-600 hover:transition-all hover:ease-in-out p-1 px-4 rounded-md"
          >
          MIS
          </button>
          <button
            onClick={() => navigate("/admin/toverify")}
            className="text-white hover:bg-slate-600 hover:transition-all hover:ease-in-out p-1 px-4 rounded-md"
          >
           To be Verify
          </button>
          <button
            onClick={() => navigate("/admin/verified")}
            className="text-white hover:bg-slate-600 hover:transition-all hover:ease-in-out p-1 px-4 rounded-md"
          >
            Verified
          </button>
          <button
            onClick={() => navigate("/admin/rejected")}
            className="text-white hover:bg-slate-600 hover:transition-all hover:ease-in-out p-1 px-4 rounded-md"
          >
            Rejected
          </button>
        </>
      );
    }
  };

  return (
    <>
      <div className="z-[2000] fixed top-0 px-2 md:h-16 h-16 overflow-hidden py-1 w-full bg-[#002b47] flex justify-between items-center">
        <div className="flex items-center">
          <IoMdMenu
            onClick={() => setShowMenu(!showMenu)}
            className="mr-5 text-xl text-white hover:cursor-pointer md:hidden block"
          />
          <div className="flex-col justify-center items-center mt-1 md:flex hidden">
            <img
              src="/brLogo.png"
              width={40}
              height={40}
              className="bg-white rounded-full hover:cursor-pointer"
              onClick={() => navigate("/")}
            />
            <Typography className="text-white" fontSize={12}>
              बिहार सरकार
            </Typography>
          </div>
          <div className="ml-8">
            <Typography className="text-white" fontSize={18} fontWeight={500}>
              अनुसूचित जाति एवं अनुसूचित जनजाति कल्याण विभाग, बिहार
            </Typography>
          </div>
        </div>
        <div className="md:block hidden">
          {!user && (
            <>
              <button
                onClick={() => navigate("/")}
                className="text-white hover:bg-slate-600 hover:transition-all hover:ease-in-out p-1 px-4 rounded-md"
              >
                Home
              </button>
              <button
                onClick={() => navigate("/aboutus")}
                className="text-white hover:bg-slate-600 hover:transition-all hover:ease-in-out p-1 px-4 rounded-md"
              >
                About us
              </button>
              <button
                onClick={() => navigate("/circular")}
                className="text-white hover:bg-slate-600 hover:transition-all hover:ease-in-out p-1 px-4 rounded-md"
              >
                Circular
              </button>

              <button
                onClick={() => navigate("/tnc")}
                className="text-white hover:bg-slate-600 hover:transition-all hover:ease-in-out p-1 px-4 rounded-md"
              >
                Terms and Condition
              </button>
            </>
          )}
          {user && (
            <>
              {renderUserLinks()}
             
              <button
                onClick={handleLogout}
                className="text-white hover:bg-slate-600 hover:transition-all hover:ease-in-out p-1 px-4 rounded-md"
              >
                Log out
              </button>
            </>
          )}
        </div>
      </div>
      {showMenu && (
        <div className="bg-[#002b47] text-white flex flex-col ease-in-out transition-all">
          <button
            onClick={() => navigate("/")}
            className="text-white hover:bg-slate-600 hover:transition-all hover:ease-in-out p-1 px-4 rounded-md"
          >
            Home
          </button>
          <button
            onClick={() => navigate("/aboutus")}
            className="text-white hover:bg-slate-600 hover:transition-all hover:ease-in-out p-1 px-4 rounded-md"
          >
            About us
          </button>
          <button
            onClick={() => navigate("/tnc")}
            className="text-white hover:bg-slate-600 hover:transition-all hover:ease-in-out p-1 px-4 rounded-md"
          >
            Terms and Condition
          </button>
          <button
            onClick={() => navigate("/aboutus")}
            className="text-white hover:bg-slate-600 hover:transition-all hover:ease-in-out p-1 px-4 rounded-md"
          >
            Circular
          </button>
          {renderUserLinks()}
          {user && (
            <button
              onClick={handleLogout}
              className="text-white hover:bg-slate-600 hover:transition-all hover:ease-in-out p-1 px-4 rounded-md"
            >
              Log out
            </button>
          )}
        </div>
      )}
    </>
  );
};

export default NavBar;
