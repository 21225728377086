import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  CardMedia,
  CircularProgress,
  Typography,
} from "@mui/material";
import { FaUpload, FaImage } from "react-icons/fa";
import Swal from "sweetalert2";
import { postData } from "../../../Api";
import { getMyProfile } from "../../../Functon/function";
import { type } from "@testing-library/user-event/dist/type";
import { IoMdDoneAll } from "react-icons/io";

const defaultAttachment = {
  adhar: "",
  caste: "",
  pic: "",
  fitness: "",
  ppo: "",
  acr: "",
};

const Attachment = () => {
  const [previews, setPreviews] = useState({
    adhar: null,
    caste: null,
    pic: null,
    fitness: null,
    ppo : null,
    acr : null,
  });
  const [formData, setFormData] = useState(defaultAttachment);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchProfileData();
  }, []);

  const fetchProfileData = async () => {
    setLoading(true);
    try {
      const profileData = await getMyProfile();
      if (profileData) {
        setPreviews({
          adhar: `https://scst.bprnetwork.in/${profileData.aadharAttachment}`,
          caste: `https://scst.bprnetwork.in/${profileData.categoryAttachment}`,
          pic: `https://scst.bprnetwork.in/${profileData.profileImage}`,
          fitness: `https://scst.bprnetwork.in/${profileData.fitnessFile}`,
          ppo : `https://scst.bprnetwork.in/${profileData.ppoFile}`,
          acr : `https://scst.bprnetwork.in/${profileData.acrFile}`,
        });
      }
    } catch (error) {
      console.error("Error fetching profile data", error);
    }
    setLoading(false);
  };

  const handleFileChange = async (e) => {
    const { name, files } = e.target;
    const file = files[0];
    if (file) {
      const previewUri = URL.createObjectURL(file);
      const base64String = await fileToBase64(file);

      setPreviews((prev) => ({
        ...prev,
        [name]: previewUri,
      }));
      setFormData((prev) => ({
        ...prev,
        [name]: base64String,
      }));
    }
  };

  const handleUpload = async (uri, data) => {
    setLoading(true);
    const fileDetails = {
      File: data,
      Subject: "",
      Description: "",
    };

    try {
      const response = await postData(`Activity/${uri}`, fileDetails);
      Swal.fire({
        title: "Success",
        text: response,
        icon: "success",
      });
    } catch (error) {
      console.error("Upload Error:", error);
      Swal.fire({
        title: "Error",
        text: error.details || "Something went wrong!",
        icon: "error",
      });
    }
    setLoading(false);
  };

  const fileToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
      reader.readAsDataURL(file);
    });
  };

  const items = [
    { name: "adhar", label: "Aadhar", uri: "aadharcardimage", type: "img"},
    { name: "caste", label: "Caste Certificate", uri: "castcertificate", type: "pdf"},
    { name: "pic", label: "Profile Picture", uri: "profileimage", type: "pdf"},
    { name: "fitness", label: "Fitness Certificate", uri: "fitnesscertificate" , type: "pdf"},
    { name: "ppo", label: "PPO", uri: "ppocertificate" , type: "pdf"},
    { name: "acr", label: "ACR", uri: "acrcertificate",  type: "pdf"},
  ];

  return (
    <>
      {loading ? (
        <div className="flex justify-center items-center w-full h-screen">
          <CircularProgress size="4rem" />
        </div>
      ) : (
        <div className="container mx-auto mt-10 px-4">
          <Typography variant="h4" className="text-center font-bold mb-6 text-gray-800">
            Upload Your Documents
          </Typography>
          <div className="grid md:grid-cols-3 gap-6">
            {items.map((item) => (
              <Card
              key={item.name}
              className="p-4 flex flex-col items-center shadow-lg hover:shadow-2xl"
            >
              {/* For image previews */}
              {item.type === "img" ? (
                previews[item.name] && !previews[item.name].includes("null") ? (
                  <>
                  {item.type==='image' ? 
                  <img
                    src={previews[item.name]}
                    width={150}
                    height={150}
                    className="w-36 h-36"
                    alt={`${item.label} preview`}
                  />
                  :
                  <a
                  href={previews[item.name]}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="flex justify-center items-center gap-4 w-36 h-36"
                >
                  <div className="w-36 h-36 flex justify-center items-center gap-4">
                  
                    <IoMdDoneAll className="text-green-600" />
                    <p className="text-xl font-semibold text-green-600">Attached</p>
                  
                  </div>
                  </a>
                }
                  </>
                ) : (
                  <div className="flex justify-center items-center gap-2 w-36 h-36">
                    <p className="text-2xl text-red-600">×</p>
                    <p className="text-lg font-semibold text-red-600">Not Attached</p>
                  </div>
                )
              ) : (
                /* For non-image previews */
                previews[item.name] && !previews[item.name].includes("null") ? (
                  <a
                    href={previews[item.name]}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="flex justify-center items-center gap-4 w-36 h-36"
                  >
                    <IoMdDoneAll className="text-green-600" />
                    <p className="text-xl font-semibold text-green-600">Attached</p>
                  </a>
                ) : (
                  <div className="flex justify-center items-center gap-4 w-36 h-36">
                    <p className="text-2xl text-red-600">×</p>
                    <p className="text-lg font-semibold text-red-600">Not Attached</p>
                  </div>
                )
              )}
            
              {/* Upload Input and Buttons */}
              <label htmlFor={item.name} className="w-full mt-4">
                <input
                  type="file"
                  id={item.name}
                  name={item.name}
                  accept={item.name == "pic" ? "image/*" : "application/pdf"}
                  className="hidden"
                  onChange={handleFileChange}
                />
                <Button
                  fullWidth
                  variant="outlined"
                  startIcon={<FaImage />}
                  component="span"
                  color="primary"
                >
                  {!previews[item.name] || previews[item.name].includes("null")
                    ? "Select"
                    : "Change"}{" "}
                  {item.label}
                </Button>
              </label>
              <Button
                className="mt-4"
                fullWidth
                variant="contained"
                color="success"
                startIcon={<FaUpload />}
                onClick={() => handleUpload(item.uri, formData[item.name])}
              >
                Upload {item.label}
              </Button>
            </Card>
            
            ))}
          </div>
          <div className="flex justify-center mt-10 gap-6">
            <Button
              className="px-6 py-2"
              variant="contained"
              color="primary"
              onClick={() => (window.location.href = "/education")}
            >
              Education
            </Button>
          </div>
        </div>
      )}
    </>
  );
};

export default Attachment;
