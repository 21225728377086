import { useTheme } from "@mui/material/styles";
import {
  Button,
  Dialog,
  DialogContent,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  Box,
  useMediaQuery,
  TableHead,
  Card,
} from "@mui/material";
import React, { useRef } from "react";
import { useReactToPrint } from "react-to-print";

const UserDetails = ({ selectedUser, close }) => {
  const contentRef = useRef(null);
  const reactToprint = useReactToPrint({ contentRef });
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const handleDownload = (url) => {
    if (!url) {
      return;
    }
    const fileUrl = `https://scst.bprnetwork.in/${url}`;
    const link = document.createElement("a");
    link.href = fileUrl;
    link.download = fileUrl.split("/").pop();
    link.target = "_blank";
    link.click();
  };

  return (
    <>
      {selectedUser && (
        <div id="'printable" className="bg-white pt-2 pb-2" ref={contentRef}>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            mt={2}
          >
            <Typography fontWeight={700} fontSize={22}>
              अनुसूचित जाति एवं अनुसूचित जनजाति कल्याण विभाग, बिहार
            </Typography>
          </Box>

          <DialogContent>
            {selectedUser && (
              <>
                {/* Personal Details */}
                <div className="flex justify-between items-center my-2">
                  <div>
                    <Typography fontWeight={600} fontSize={20}>
                      Applicant Details
                    </Typography>
                  </div>
                  <div>
                    <Typography fontWeight={700}>
                      System Ref. No. : {selectedUser.member.id}
                    </Typography>
                    <Typography fontWeight={700}>
                      Entry Date :{" "}
                      {new Date(
                        selectedUser.member.entrydate
                      ).toLocaleDateString("en-GB", {
                        day: "2-digit",
                        month: "2-digit",
                        year: "2-digit",
                      })}
                    </Typography>
                  </div>
                </div>

                <div className="flex justify-between my-2">
                  <TableContainer component={Paper} className="mr-2">
                    <Table>
                      <TableBody>
                        <TableRow>
                          <TableCell style={{ border: "1px solid black" }}>
                            <strong>Name</strong>
                          </TableCell>
                          <TableCell style={{ border: "1px solid black" }}>
                            {selectedUser.member.name}
                          </TableCell>
                          <TableCell style={{ border: "1px solid black" }}>
                            <strong>Father's Name</strong>
                          </TableCell>
                          <TableCell style={{ border: "1px solid black" }}>
                            {selectedUser.member.fsName}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell style={{ border: "1px solid black" }}>
                            <strong>Gender</strong>
                          </TableCell>
                          <TableCell style={{ border: "1px solid black" }}>
                            {selectedUser.member.gender}
                          </TableCell>
                          <TableCell style={{ border: "1px solid black" }}>
                            <strong>Category</strong>
                          </TableCell>
                          <TableCell style={{ border: "1px solid black" }}>
                            {selectedUser.member.category}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell style={{ border: "1px solid black" }}>
                            <strong>Date of Birth</strong>
                          </TableCell>
                          <TableCell style={{ border: "1px solid black" }}>
                            {selectedUser.member.dob}
                          </TableCell>
                          <TableCell style={{ border: "1px solid black" }}>
                            <strong>Date of Joining</strong>
                          </TableCell>
                          <TableCell style={{ border: "1px solid black" }}>
                            {selectedUser.member.doj}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell style={{ border: "1px solid black" }}>
                            <strong>First Joined School</strong>
                          </TableCell>
                          <TableCell style={{ border: "1px solid black" }}>
                            {selectedUser.member.firstSchoolName}
                          </TableCell>
                          <TableCell style={{ border: "1px solid black" }}>
                            <strong>Last Joined School</strong>
                          </TableCell>
                          <TableCell style={{ border: "1px solid black" }}>
                            {selectedUser.member.lastSchoolName}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>

                  <Card>
                    <img
                      src={`https://scst.bprnetwork.in/${selectedUser.member.profileImage}`}
                      width={80}
                      height={70}
                      className="w-52 h-52 "
                    />
                  </Card>
                </div>

                <TableContainer component={Paper}>
                  <Table style={{ borderCollapse: "collapse" }}>
                    <TableBody>
                      <TableRow>
                        <TableCell style={{ border: "1px solid black" }}>
                          <strong>Date of Retirement</strong>
                        </TableCell>
                        <TableCell style={{ border: "1px solid black" }}>
                          {selectedUser.member.dor}
                        </TableCell>
                        <TableCell style={{ border: "1px solid black" }}>
                          <strong>Last Salary</strong>
                        </TableCell>
                        <TableCell style={{ border: "1px solid black" }}>
                          {selectedUser.member.lastSalary}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell style={{ border: "1px solid black" }}>
                          <strong>Subjects</strong>
                        </TableCell>
                        <TableCell style={{ border: "1px solid black" }}>
                          {selectedUser.member.subject}
                        </TableCell>
                        <TableCell style={{ border: "1px solid black" }}>
                          <strong>Mobile</strong>
                        </TableCell>
                        <TableCell style={{ border: "1px solid black" }}>
                          {selectedUser.member.mobile}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell style={{ border: "1px solid black" }}>
                          <strong>Email</strong>
                        </TableCell>
                        <TableCell style={{ border: "1px solid black" }}>
                          {selectedUser.member.email}
                        </TableCell>
                        <TableCell style={{ border: "1px solid black" }}>
                          <strong>Applied Post</strong>
                        </TableCell>
                        <TableCell style={{ border: "1px solid black" }}>
                          {selectedUser.member.applyPost}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell style={{ border: "1px solid black" }}>
                          <strong>Retired Post</strong>
                        </TableCell>
                        <TableCell style={{ border: "1px solid black" }}>
                          {selectedUser.member.retiredPost}
                        </TableCell>
                        <TableCell style={{ border: "1px solid black" }}>
                          <strong>Retired Department</strong>
                        </TableCell>
                        <TableCell style={{ border: "1px solid black" }}>
                          {selectedUser.member.retiredDept}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell style={{ border: "1px solid black" }}>
                          <strong>Fixed Pension</strong>
                        </TableCell>
                        <TableCell style={{ border: "1px solid black" }}>
                          {selectedUser.member.fixedPension}
                        </TableCell>
                        <TableCell style={{ border: "1px solid black" }}>
                          <strong>Getting Pension</strong>
                        </TableCell>
                        <TableCell style={{ border: "1px solid black" }}>
                          {selectedUser.member.gettingPension}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>

                <div className="mt-8 my-2">
                  <Typography fontSize={22} fontWeight={20}>
                    Address
                  </Typography>
                </div>
                <TableContainer component={Paper}>
                  <Table style={{ borderCollapse: "collapse" }}>
                    <TableHead>
                      <TableRow>
                        <TableCell style={{ border: "1px solid black" }}>
                          <strong>Address 1</strong>
                        </TableCell>
                        <TableCell style={{ border: "1px solid black" }}>
                          <strong>Address 2</strong>
                        </TableCell>
                        <TableCell style={{ border: "1px solid black" }}>
                          <strong>Block</strong>
                        </TableCell>
                        <TableCell style={{ border: "1px solid black" }}>
                          <strong>District</strong>
                        </TableCell>
                        <TableCell style={{ border: "1px solid black" }}>
                          <strong>Pin Code</strong>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell style={{ border: "1px solid black" }}>
                          {selectedUser.member.pAddress1}
                        </TableCell>
                        <TableCell style={{ border: "1px solid black" }}>
                          {selectedUser.member.pAddress2}
                        </TableCell>
                        <TableCell style={{ border: "1px solid black" }}>
                          {selectedUser.member.pBlock}
                        </TableCell>
                        <TableCell style={{ border: "1px solid black" }}>
                          {selectedUser.member.pDistrict}
                        </TableCell>
                        <TableCell style={{ border: "1px solid black" }}>
                          {selectedUser.member.pPincode}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
                <div>
                  <Card
                    elevation={2}
                    className="mt-4 p-4 flex justify-between items-center flex-wrap"
                  >
                    <Typography style={{ marginTop: 4 }}>
                      <strong>
                        AADHAR Card : {selectedUser.member.aadharNo}
                      </strong>{" "}
                      <p
                        onClick={() =>
                          handleDownload(selectedUser.member.aadharAttachment)
                        }
                        className={`${
                          selectedUser.member.aadharAttachment
                            ? "text-green-600"
                            : "text-red-600"
                        } hover:cursor-pointer`}
                      >
                        {selectedUser.member.aadharAttachment !== ""
                          ? "Attached"
                          : "Not Attached"}{" "}
                      </p>{" "}
                    </Typography>
                    <Typography style={{ marginTop: 4 }}>
                      <strong>Caste Certificate : </strong>
                      <p
                        onClick={() =>
                          handleDownload(selectedUser.member.categoryAttachment)
                        }
                        className={`${
                          selectedUser.member.categoryAttachment
                            ? "text-green-600"
                            : "text-red-600"
                        } hover:cursor-pointer`}
                      >
                        {selectedUser.member.categoryAttachment !== ""
                          ? "Attached"
                          : "Not Attached"}
                      </p>{" "}
                    </Typography>
                    <Typography style={{ marginTop: 4 }}>
                      <strong>Fitness Certificate : </strong>
                      <p
                        onClick={() =>
                          handleDownload(selectedUser.member.fitnessFile)
                        }
                        className={`${
                          selectedUser.member.fitnessFile
                            ? "text-green-600"
                            : "text-red-600"
                        } hover:cursor-pointer`}
                      >
                        {selectedUser.member.fitnessFile !== null
                          ? "Attached"
                          : "Not Attached"}
                      </p>{" "}
                    </Typography>
                    <Typography style={{ marginTop: 4 }}>
                      <strong>PPO Certificate : </strong>
                      <p
                        onClick={() =>
                          handleDownload(selectedUser.member.ppoFile)
                        }
                        className={`${
                          selectedUser.member.ppoFile
                            ? "text-green-600"
                            : "text-red-600"
                        } hover:cursor-pointer`}
                      >
                        {selectedUser.member.ppoFile !== null
                          ? "Attached"
                          : "Not Attached"}
                      </p>{" "}
                    </Typography>
                    <Typography style={{ marginTop: 4 }}>
                      <strong>ACR Certificate : </strong>
                      <p
                        onClick={() =>
                          handleDownload(selectedUser.member.acrFile)
                        }
                        className={`${
                          selectedUser.member.acrFile
                            ? "text-green-600"
                            : "text-red-600"
                        } hover:cursor-pointer`}
                      >
                        {selectedUser.member.acrFile !== null
                          ? "Attached"
                          : "Not Attached"}
                      </p>{" "}
                    </Typography>
                  </Card>
                </div>

                <div className="mt-8">
                  <Typography fontWeight={600} fontSize={20}>
                    Experience
                  </Typography>
                </div>
                <TableContainer component={Paper}>
                  <Table style={{ borderCollapse: "collapse" }}>
                    <TableHead>
                      <TableRow>
                        <TableCell style={{ border: "1px solid black" }}>
                          <strong>Institute</strong>
                        </TableCell>
                        <TableCell style={{ border: "1px solid black" }}>
                          <strong>Subjects</strong>
                        </TableCell>
                        <TableCell style={{ border: "1px solid black" }}>
                          <strong>Date of Joining</strong>
                        </TableCell>
                        <TableCell style={{ border: "1px solid black" }}>
                          <strong>Date of Transfer</strong>
                        </TableCell>
                        <TableCell style={{ border: "1px solid black" }}>
                          <strong>Attachment</strong>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {selectedUser.services.map((service, index) => (
                        <TableRow key={index}>
                          <TableCell style={{ border: "1px solid black" }}>
                            {service.institute || "N/A"}
                          </TableCell>
                          <TableCell style={{ border: "1px solid black" }}>
                            {service.subject ? service.subject : "N/A"}
                          </TableCell>
                          <TableCell style={{ border: "1px solid black" }}>
                            {service.doj
                              ? new Date(service.doj).toLocaleDateString()
                              : "N/A"}
                          </TableCell>
                          <TableCell style={{ border: "1px solid black" }}>
                            {service.dot
                              ? new Date(service.dot).toLocaleDateString()
                              : "N/A"}
                          </TableCell>
                          <TableCell
                            onClick={() => handleDownload(service.attachment)}
                            style={{ border: "1px solid black" }}
                          >
                            {service.attachment ? (
                              <span
                                className="hover:cursor-pointer"
                                style={{ color: "green" }}
                              >
                                Attached
                              </span>
                            ) : (
                              <span style={{ color: "red" }}>
                                No Attachment
                              </span>
                            )}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>

                <div className="mt-8 my-2">
                  <Typography fontSize={22} fontWeight={20}>
                    Score Details
                  </Typography>
                </div>
                <TableContainer component={Paper}>
                  <Table style={{ borderCollapse: "collapse" }}>
                    <TableHead>
                      <TableRow>
                        <TableCell style={{ border: "1px solid black" }}>
                          <strong>Area of Consideration</strong>
                        </TableCell>

                        <TableCell
                          colSpan={2}
                          style={{ border: "1px solid black" }}
                        >
                          <strong className="text-center">Gain Score</strong>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {/* {selectedUser. console.log(selectedUser.grade)} */}
                      {selectedUser?.grade?.map((gradeItem, index) => (
                        <TableRow key={index}>
                          {console.log(gradeItem)}
                          <TableCell style={{ border: "1px solid black" }}>
                            {gradeItem["area_of_consideration"]}
                          </TableCell>
                          <TableCell style={{ border: "1px solid black" }}>
                            {gradeItem["mark"]}
                          </TableCell>
                          <TableCell style={{ border: "1px solid black" }}>
                            {gradeItem["points"]}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>

                {/* Print Button */}
              </>
            )}
          </DialogContent>
        </div>
      )}
      <div className="flex justify-between items-center p-4 bg-white mb-4">
        <Button onClick={close} variant="contained" color="inherit">
          Close
        </Button>
        <Button
          onClick={() => reactToprint()}
          variant="contained"
          color="primary"
        >
          Print
        </Button>
      </div>
    </>
  );
};

export default UserDetails;
