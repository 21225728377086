import { Box, Button, Container, Typography } from "@mui/material";
import React from "react";

const Footer = () => {
  return (
    <>
      <div className="w-full bg-[#002b47] text-white">
        <div className="flex justify-between items-center p-2">
          
            <Typography
              color="white"
              fontWeight={700}
              fontSize={18}
              className="px-2 text-black"
            >
              SC & ST Welfare Department, GoB.
            </Typography>
       
          <div
            className="flex justify-end items-center"
            style={{
              justifyContent: "end",
              position: "relative",
              right: 50,
            }}
          >
            <p className="hover:cursor-pointer hover:shadow-lg shadow-[#002b47]  ease-in-out transition-all scale-105" onClick={()=>window.location.href="https://bprnetwork.in/"}>Designed By BPR Network</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
