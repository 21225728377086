import React, { useEffect, useState } from "react";
import {
  Button,
  TextField,
  Card,
  CardContent,
  Grid,
  Dialog,
  Autocomplete,
  Typography,
} from "@mui/material";
import { FaEye } from "react-icons/fa";
import Swal from "sweetalert2";
import { delteData, postData } from "../../../Api";
import { callerFunction } from "../../../Api";
import { getSubjects } from "../../../Functon/function";

const defaultexperiencelist = {
  id: 0,
      institute: "",
      subject: "",
      doj: "1990-01-01",
      dot: "1990-01-01",
      attachment: "",
      memberId: 0,
}


const Experience = () => {
  const [preview, setPreview] = useState({ open: false, content: "" });
  const [subjects, setSubjects] = useState([]);
  const [loading, setLoader] = useState(false);
  const [serviceData, setServviceData] = useState([]);
  const [experienceList, setExperienceList] = useState([
    defaultexperiencelist
  ]);

  console.log(experienceList) 

  const loader = (v) => {
    setLoader(v);
  };

  const getServicesDetails = async () => {
 
    const services = await callerFunction("Activity/myservicelist");
    setServviceData(services);
    
  };

  const handleInputChange = (index, event) => {
    const { name, value } = event.target;
    const newExperienceList = [...experienceList];
    newExperienceList[index][name] = value;
    setExperienceList(newExperienceList);
  };

  const handleDateChange = (index, dateField, value) => {
    const newExperienceList = [...experienceList];
    newExperienceList[index][dateField] = value;
    setExperienceList(newExperienceList);
  };

  const handleFileChange = (index, event) => {
    const file = event.target.files[0];
    if (file) {
      const newExperienceList = [...experienceList];
      newExperienceList[index].attachment = file;

      const reader = new FileReader();
      reader.onloadend = () => {
        newExperienceList[index].attachment = reader.result; // Base64 string
        setExperienceList(newExperienceList);
      };
      reader.readAsDataURL(file);
    }
  };

  const handlePreview = (content) => {
    setPreview({ open: true, content });
  };

  const handleClosePreview = () => {
    setPreview({ open: false, content: "" });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    loader(true);
    const submitData = experienceList.map(
      ({ attachmentPreview, ...rest }) => rest
    );
    try {
      const res = await postData("Activity/newservice", submitData);
      Swal.fire({
        title: "Success",
        icon: "success",
        text: res,
      });
      getServicesDetails();
    } catch (e) {
      console.error(e);
      Swal.fire({
        title: "Error",
        icon: "error",
        text: e.details || "An error occurred while submitting data.",
      });
    }
    loader(false);
  };

  const getSubject = async () => {
    loader(true);
    const res = await getSubjects();
    setSubjects(res);
    loader(false);
  };

  useEffect(() => {
    getSubject();
    getServicesDetails();
  }, []);

  return (
    <>
      <div className="container mt-4 mb-4">
        <p className="text-center mb-4 text-xl font-semibold mt-20">Experience Details</p>
        {experienceList.map((experience, index) => (
          <Card key={index} className="mb-3">
            <CardContent>
              <form onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Institute"
                    name="institute"
                    variant="outlined"
                    required
                    value={experience.institute}
                    onChange={(e) => handleInputChange(index, e)}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Autocomplete
                    multiple
                  
                    options={subjects.map((item) => item.sname)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        fullWidth
                        label="Subject"
                        name="subject"
                        variant="outlined"
                        required = {experience.subject===''}
                      />
                    )}
                    onChange={(event, newValue) => {
                      const selectedSubjects = newValue.join(", ");
                      const newExperienceList = [...experienceList];
                      newExperienceList[index].subject = selectedSubjects;
                      setExperienceList(newExperienceList);
                    }}
                    value={
                      experience.subject ? experience.subject.split(", ") : []
                    }
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Date of Joining"
                    name="doj"
                    type="date"
                    required
                    variant="outlined"
                    value={experience.doj || ""}
                    onChange={(e) =>
                      handleDateChange(index, "doj", e.target.value)
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Date of Transfer/Retirenment"
                    name="dot"
                    type="date"
                    required
                    variant="outlined"
                    value={experience.dot || ""}
                    onChange={(e) =>
                      handleDateChange(index, "dot", e.target.value)
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <label htmlFor={`file-upload-${index}`}>
                    <input
                      type="file"
                      id={`file-upload-${index}`}
                      style={{ display: "none" }}
                      onChange={(e) => handleFileChange(index, e)}
                    />
                    <Button
                      variant="outlined"
                      color="primary"
                      component="span"
                      fullWidth
                    >
                      Upload Attachment
                    </Button>
                  </label>
                  {experience.attachment && (
                    <FaEye
                      style={{
                        marginLeft: 8,
                        fontSize: 22,
                        cursor: "pointer",
                      }}
                      onClick={() => handlePreview(experience.attachment)}
                    />
                  )}
                </Grid>
              </Grid>
              <div className='flex justify-center items-baseline mt-4'>
                    <button type="submit" className="bg-[#002b47] rounded-md p-2 px-4 hover:cursor-pointer text-white  hover:shadow-md hover:shadow-black">Submit</button>
                  </div>
              </form>
            </CardContent>
          </Card>
        ))}
        
        <div className="container mt-4">
          <div className="grid md:grid-cols-3 grid-cols-1 gap-4">
            {serviceData.length!==0 && serviceData.map((item, index) => (
              
                <Card elevation={2} className=" hover:shadow-black hover:shadow-md hover:ease-in-out hover:transition-transform">
                  <CardContent>
                    <Grid container spacing={2}>
                      <Grid
                        item
                        xs={12}
                        className="flex justify-between px-4"
                      >
                        <strong>Instiute :</strong>
                        <Typography>{item.institute}</Typography>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        className="flex justify-between px-4"
                      >
                        <strong>Subjects :</strong> {item.subject}
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        className="flex justify-between px-4"
                      >
                        <strong>Date of Joining :</strong> {item.doj}
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        className="flex justify-between px-4"
                      >
                        <strong>Date of Transfer/Retirement :</strong>{" "}
                        {item.dot}
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        className="flex justify-between px-4"
                      >
                        <strong>Attachment:</strong>{" "}
                        {item.attachment ? (
                          <a
                            href={`https://scst.bprnetwork.in/${item.attachment}`}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            View Document
                          </a>
                        ) : (
                          "No Attachment"
                        )}
                      </Grid>
                    </Grid>
                    <div className="flex justify-center mt-4 h-6">
                    <Button
  onClick={() => {
    Swal.fire({
      title: 'Are you sure?',
      text: 'Do you want to Delete?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, Delete',
      cancelButtonText: 'No, Cancel',
      confirmButtonColor: 'red',
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          
          const response = await delteData(`Activity/deleteservice?id=${item.id}`); 
          
          if (response) { // check if delete was successful
            Swal.fire({
              icon: 'success',
              title: 'Deleted!',
              text: response,
            });
            getServicesDetails();
          }
        } catch (error) {
          Swal.fire({
            icon: 'error',
            title: 'Error!',
            text: error.details,
          });
        }
      }
    });
  }}
  variant="contained"
  color="error"
>
  Delete
</Button>
                    </div>
                  </CardContent>
                </Card>
             
            ))}
          </div>
        </div>
      </div>
      <div className="mt-4 mb-2 flex justify-center items-center flex-col">
            <Card
              elevation={2}
              className="px-16 p-6 flex flex-col justify-center items-center"
            >
              <Typography fontWeight={600} fontSize={18}>
                Complete
              </Typography>
              <div className="flex justify-center items-center gap-8 mt-2">
                <button onClick={()=>window.location.href='/education'} className="bg-[#002b47] p-2 px-4 hover:cursor-pointer text-white rounded-sm hover:shadow-md hover:shadow-black">
                  Education
                </button>
                <button onClick={()=>window.location.href='/pan'} className="bg-green-700 p-2 px-4 hover:cursor-pointer text-white rounded-sm hover:shadow-md hover:shadow-black">
                  Documents
                </button>
              </div>
            </Card>
          </div>

      <Dialog open={preview.open} onClose={handleClosePreview}>
        <div style={{ padding: "20px" }}>
          {preview.content && preview.content.endsWith(".pdf") ? (
            <embed
              src={preview.content}
              type="application/pdf"
              width="100%"
              height="500px"
            />
          ) : (
            <img
              src={preview.content}
              alt="Attachment Preview"
              style={{
                width: "100%",
                maxHeight: "500px",
                objectFit: "contain",
              }}
            />
          )}
        </div>
      </Dialog>
    </>
  );
};

export default Experience;
