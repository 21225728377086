import Swal from "sweetalert2";
import { delteData, fetchData } from "../Api";




export const getDistrict = async() =>{
    try{
      const res = await fetchData('Master/districtlist');
      if(res){
        return res
      }
    }catch(e){
      console.error(e);
      return 
    }
  }

export  const getGenders = async() =>{
    try{
      const res = await fetchData('Master/gender');
      if(res){
        return res;
      }
    }catch(e){
      console.error(e);
      return
    }
  }

 export const getCategories = async()=>{
   try {
     const res = await fetchData('Master/categorylist');
     if(res){
       return res;
     }
   } catch (error) {
    console.error(error)
   }
  }

  export const getSubjects = async()=>{
    try{
        const res = await fetchData('Master/subjectlist')
        if(res){
            return res;
        }
    }catch(error){
        console.error(error)
        return
    }
  }

  export const getMyProfile = async()=>{
    try{
        const res = await fetchData('Activity/myprofile');
        return res;
    }catch(error){
        console.error(error)
        Swal.fire({
            icon: 'error',
            title : 'Error',
            text : error.details
        })
        return;
    }
  }

  export const callerFunction = async(uri)=>{
    try{
        const res = await fetchData(uri);
        return res;
    }catch(error){
        console.error(error)
        Swal.fire({
            icon: 'error',
            title : 'Error',
            text : error.details
        })
        return;
    }
  }

  export const deleteFunction = async(id)=>{
    const res = await delteData()
  }
  

