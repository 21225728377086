import React, { useEffect, useState } from 'react'
import { callerFunction } from '../../../Api';
import {
    Button,
    Dialog,
    div,
    DialogContent,
    DialogTitle,
    Typography,
    Grid,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Box,
    useMediaQuery,
    CircularProgress,
  } from "@mui/material";
  import { useTheme } from "@mui/material/styles";
  import UserDetails from '../User/UserDetails';


const Rejected = () => {

    const [data, setData] = useState([]);
    const [open, setOpen] = useState(false);
    const [loader, setLoader] = useState(false);
    const [selectedUser, setSelectedUser] = useState(null);


    const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

    useEffect(()=>{
        getRejectedApplicants();
    }, [])

    const getRejectedApplicants = async() =>{
        const res = await callerFunction('Action/rejectedapplicantlist');
        if(res){
            setData(res);
        }
    }

    const handleOpenDialog = async (id) => {
        setLoader(true);
        const x = await callerFunction(`Action/applicantdetails?id=${id}`);
        setSelectedUser(x);
        setOpen(true);
        setLoader(false);
      };
    
      const handleCloseDialog = () => {
        setSelectedUser(null);
        setOpen(false);
      };



  return (
    <>
    {loader ? 
    <div className="flex justify-center items-center w-full h-screen">
    <CircularProgress size="4rem" />
  </div>: 
    (
        <>
         {!open &&
         <div className="container py-5">
         <Typography
           variant="h4"
           fontWeight={700}
           align="center"
           style={{ marginBottom: "30px" }}
         >
           To Verify Applicants
         </Typography>

         {/* Table/Grid Layout */}
         {!isMobile ? (
           // Desktop View
           <TableContainer
             component={Paper}
             style={{ boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)" }}
           >
             <Table>
               <TableHead style={{ backgroundColor: "#f5f5f5" }}>
                 <TableRow>
                   <TableCell>
                     <strong>Name</strong>
                   </TableCell>
                   <TableCell>
                     <strong>Email</strong>
                   </TableCell>
                   <TableCell>
                     <strong>Phone</strong>
                   </TableCell>
                   <TableCell>
                     <strong>Actions</strong>
                   </TableCell>
                 </TableRow>
               </TableHead>
               <TableBody>
                 {data.map((user, index) => (
                   <TableRow key={index}>
                     <TableCell>{user.name}</TableCell>
                     <TableCell>{user.email}</TableCell>
                     <TableCell>{user.mobile}</TableCell>
                     <TableCell>
                       <Button
                         variant="contained"
                         color="primary"
                         onClick={() => handleOpenDialog(user.id)}
                       >
                         View Details
                       </Button>
                     </TableCell>
                   </TableRow>
                 ))}
               </TableBody>
             </Table>
           </TableContainer>
         ) : (
           // Mobile View - Show key-value pairs
           <Grid container spacing={2}>
             {data.map((user, index) => (
               <Grid item xs={12} key={index} style={{ marginBottom: "15px" }}>
                 <Paper
                   style={{
                     padding: "10px",
                     boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
                   }}
                 >
                   <Box>
                     <Typography variant="body2">
                       <strong>Name:</strong> {user.name}
                     </Typography>
                     <Typography variant="body2">
                       <strong>Email:</strong> {user.email}
                     </Typography>
                     <Typography variant="body2">
                       <strong>Phone:</strong> {user.mobile}
                     </Typography>
                     <Button
                       variant="contained"
                       color="primary"
                       style={{ marginTop: "10px" }}
                       onClick={() => handleOpenDialog(user.id)}
                     >
                       View Details
                     </Button>
                   </Box>
                 </Paper>
               </Grid>
             ))}
           </Grid>
         )}
         

    
       </div>
         }
         {open &&
         <>
         <UserDetails selectedUser={selectedUser} close={handleCloseDialog}/>
         </>
         }
        </>
    )
}
    </>

  )
}

export default Rejected