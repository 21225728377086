import React from 'react';
import { Card, Typography, Box } from '@mui/material';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import { useNavigate } from 'react-router-dom';

// Helper function to get icons
const getIcon = (name) => {
  switch (name) {
    case 'New Reg. & Uncomplete Profile':
      return <PersonAddIcon style={{ color: '#f39c12', fontSize: 40 }} />;
    case 'To Verify':
      return <HourglassEmptyIcon style={{ color: '#3498db', fontSize: 40 }} />;
    case 'Verified':
      return <VerifiedUserIcon style={{ color: '#2ecc71', fontSize: 40 }} />;
    case 'Rejected':
      return <ErrorOutlineIcon style={{ color: '#e74c3c', fontSize: 40 }} />;
    default:
      return <PersonAddIcon style={{ color: '#7f8c8d', fontSize: 40 }} />;
  }
};

const getURI = (name) => {
  switch (name) {
    case 'New Reg. & Uncomplete Profile':
      return '/admin/newApplicants';
    case 'To Verify':
      return '/admin/toverify';
    case 'Verified':
      return '/admin/verified';
    case 'Rejected':
      return '/admin/rejected';
    default:
      return '/';
  }
};

const AdminDashboard = ({ dashboardData }) => {
  const navigate = useNavigate();

  const handleClick = (name) => {
    const uri = getURI(name);
    console.log(uri);
    navigate(uri); 
  };

  return (
    <div className="container py-5 h-screen">
      <Typography variant="h4" fontWeight={700} align="center" style={{ marginBottom: '30px' }}>
        Dashboard
      </Typography>

      <div className="grid md:grid-cols-2 grid-cols-1 gap-4">
        {dashboardData.map((item, index) => (
          <Card
            key={index}
            onClick={() => handleClick(item.name)}
            style={{
              flex: 1,
              padding: '20px',
              paddingInline: '40px',
              borderRadius: '10px',
              backgroundColor: '#f5f5f5',
              boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
              transition: '0.3s',
              height: '100%',
              cursor: 'pointer',
            }}
          >
            <Box display="flex" alignItems="center" justifyContent="space-between">
              {getIcon(item.name)}
              <Box textAlign="right">
                <Typography style={{ fontWeight: 600 }}>{item.name}</Typography>
                <Typography variant="h4" style={{ color: '#333' }}>
                  {item.qty}
                </Typography>
              </Box>
            </Box>
          </Card>
        ))}
      </div>
    </div>
  );
};

export default AdminDashboard;
