import React, { useState, useEffect } from "react";
import {
  Button,
  Dialog,
  div,
  DialogContent,
  DialogTitle,
  Typography,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  useMediaQuery,
  CircularProgress,
} from "@mui/material";
import { callerFunction, postData } from "../../../Api";
import { useTheme } from "@mui/material/styles";
import Swal from "sweetalert2";
import UserDetails from "../User/UserDetails";

const ToVerify = () => {
  const [data, setData] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [open, setOpen] = useState(false);
  const [loader, setLoader] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    getData();
  }, []);

  const userId = JSON.parse(localStorage.getItem('user'))?.usertypeId

  const getData = async () => {
    const x = await callerFunction("Action/newapplicantlist");
    setData(x);
  };

  const handleOpenDialog = async (id) => {
    setLoader(true);
    const x = await callerFunction(`Action/applicantdetails?id=${id}`);
    setSelectedUser(x);
    setOpen(true);
    setLoader(false);
  };

  const handleCloseDialog = () => {
    setSelectedUser(null);
    setOpen(false);
  };

  const handleApprove = async (id) => {
    const result = await Swal.fire({
      title: "Are you sure?",
      text: "Do you want to approve this applicant?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, approve it!",
      cancelButtonText: "No, cancel",
    });

    if (result.isConfirmed) {
      setLoader(true);

      const x = await callerFunction(`Action/approveapplicant?id=${id}`);
      Swal.fire({
        title: "Approved",
        icon: "success",
        text: x,
      });
      getData();
      setLoader(false);
      handleCloseDialog();
    }
  };

  const handleReject = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "Do you want to reject?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, Reject",
      cancelButtonText: "No, Cancel",
      confirmButtonColor: "red",
    }).then((result) => {
      if (result.isConfirmed) {
        // Show a second prompt for the rejection reason
        Swal.fire({
          title: "Reason for Rejection",
          input: "text",
          inputPlaceholder: "Enter your reason here...",
          showCancelButton: true,
          confirmButtonText: "Submit",
          cancelButtonText: "Cancel",
          preConfirm: (reason) => {
            if (!reason) {
              Swal.showValidationMessage("Please provide a reason for rejection");
              return false;
            }
            return reason; 
          },
        }).then(async (result) => {
         
          if (result.isConfirmed) {
          
            try {
              
              
            console.log()
              const formData = {
                id : 0,
                narration : result.value,
                refId : selectedUser.member.id,
                tableId : '0',
                memberId : selectedUser.member.id,
                actionby : '0',
                ondate: new Date().toISOString()
              } 
              console.log('hello')
              
              console.log(formData);
              const response = await postData('Action/rejectapplicant', formData);
  
              if (response) {
                Swal.fire({
                  icon: "success",
                  title: "Rejected!",
                  text: response
                });
                getData();
                handleCloseDialog(); 
              }
            } catch (error) {
              Swal.fire({
                icon: "error",
                title: "Error!",
                text: error.details || error || "Failed to reject the request.",
              });
            }
          }
        });
      }
    });
  };
  

  return (
    <>
      {loader ? (
        <div className="flex justify-center items-center w-full h-screen">
          <CircularProgress size="4rem" />
        </div>
      ) : (
        <>
        {!open &&
        <div className="container py-5">
          <Typography
            variant="h4"
            fontWeight={700}
            align="center"
            style={{ marginBottom: "30px" }}
          >
            To Verify Applicants
          </Typography>

          {/* Table/Grid Layout */}
          {!isMobile ? (
            // Desktop View
            <TableContainer
              component={Paper}
              style={{ boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)" }}
            >
              <Table>
                <TableHead style={{ backgroundColor: "#f5f5f5" }}>
                  <TableRow>
                    <TableCell>
                      <strong>Name</strong>
                    </TableCell>
                    <TableCell>
                      <strong>Email</strong>
                    </TableCell>
                    <TableCell>
                      <strong>Phone</strong>
                    </TableCell>
                    <TableCell>
                      <strong>Actions</strong>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.map((user, index) => (
                    <TableRow key={index}>
                      <TableCell>{user.name}</TableCell>
                      <TableCell>{user.email}</TableCell>
                      <TableCell>{user.mobile}</TableCell>
                      <TableCell>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => handleOpenDialog(user.id)}
                        >
                          View Details
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            // Mobile View - Show key-value pairs
            <Grid container spacing={2}>
              {data.map((user, index) => (
                <Grid item xs={12} key={index} style={{ marginBottom: "15px" }}>
                  <Paper
                    style={{
                      padding: "10px",
                      boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
                    }}
                  >
                    <Box>
                      <Typography variant="body2">
                        <strong>Name:</strong> {user.name}
                      </Typography>
                      <Typography variant="body2">
                        <strong>Email:</strong> {user.email}
                      </Typography>
                      <Typography variant="body2">
                        <strong>Phone:</strong> {user.mobile}
                      </Typography>
                      <Button
                        variant="contained"
                        color="primary"
                        style={{ marginTop: "10px" }}
                        onClick={() => handleOpenDialog(user.id)}
                      >
                        View Details
                      </Button>
                    </Box>
                  </Paper>
                </Grid>
              ))}
            </Grid>
          )}
          

     
        </div>
        }
        {open && (
            <>
              <UserDetails
                selectedUser={selectedUser}
                close={handleCloseDialog}
              />
              <div className="flex justify-between items-center px-4 p-1">
                <Button
                  variant="contained"
                  color="error"
                  onClick={() => handleReject()}
                >
                  Reject
                </Button>
                <Button
                  variant="contained"
                  color="success"
                  onClick={() => handleApprove(selectedUser.member.id)}
                >
                  Approve
                </Button>
              </div>
            </>
          )}
        </>
      )}
    </>
  );
};

export default ToVerify;
