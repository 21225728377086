import React, { useEffect, useState } from "react";
import { callerFunction } from "../../../Api";
import { useTheme } from "@mui/material/styles";
import {
  Button,
  Dialog,
  div,
  DialogContent,
  DialogTitle,
  Typography,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  useMediaQuery,
  CircularProgress,
} from "@mui/material";
import UserDetails from "../User/UserDetails";

const Verified = () => {
  const theme = useTheme();
  const [data, setData] = useState([]);
  const [loader, setLoader] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [open, setOpen] = useState(false);

  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    getVerifiedUsers();
  }, []);

  const getVerifiedUsers = async () => {
    setLoader(true);
    const res = await callerFunction("Action/approvedapplicantlist");
    setData(res);
    setLoader(false);
  };

  const handleOpenDialog = async (id) => {
    setLoader(true);
    const x = await callerFunction(`Action/applicantdetails?id=${id}`);
    setSelectedUser(x);
    setOpen(true);
    setLoader(false);
  };

  const handleCloseDialog = () => {
    setSelectedUser(null);
    setOpen(false);
  };

  return (
    <>
      {loader ? (
        <div className="flex justify-center items-center w-full h-screen">
          <CircularProgress size="4rem" />
        </div>
      ) : (
        <>
          {!open && (
            <div>
              {!open && (
                <>
                  {!isMobile ? (
                    // Desktop View
                    <TableContainer
                      component={Paper}
                      style={{ boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)" }}
                    >
                      <Table>
                        <TableHead style={{ backgroundColor: "#f5f5f5" }}>
                          <TableRow>
                            <TableCell>
                              <strong>Name</strong>
                            </TableCell>
                            <TableCell>
                              <strong>Email</strong>
                            </TableCell>
                            <TableCell>
                              <strong>Phone</strong>
                            </TableCell>
                            <TableCell>
                              <strong>Actions</strong>
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {data.map((user, index) => (
                            <TableRow key={index}>
                              <TableCell>{user.name}</TableCell>
                              <TableCell>{user.email}</TableCell>
                              <TableCell>{user.mobile}</TableCell>
                              <TableCell>
                                <Button
                                  variant="contained"
                                  color="primary"
                                  onClick={() => handleOpenDialog(user.id)}
                                >
                                  View Details
                                </Button>
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  ) : (
                    // Mobile View - Show key-value pairs
                    <Grid container spacing={2}>
                      {data.map((user, index) => (
                        <Grid
                          item
                          xs={12}
                          key={index}
                          style={{ marginBottom: "15px" }}
                        >
                          <Paper
                            style={{
                              padding: "10px",
                              boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
                            }}
                          >
                            <Box>
                              <Typography variant="body2">
                                <strong>Name:</strong> {user.name}
                              </Typography>
                              <Typography variant="body2">
                                <strong>Email:</strong> {user.email}
                              </Typography>
                              <Typography variant="body2">
                                <strong>Phone:</strong> {user.mobile}
                              </Typography>
                              <Button
                                variant="contained"
                                color="primary"
                                style={{ marginTop: "10px" }}
                                onClick={() => handleOpenDialog(user.id)}
                              >
                                View Details
                              </Button>
                            </Box>
                          </Paper>
                        </Grid>
                      ))}
                    </Grid>
                  )}
                </>
              )}
            </div>
          )}
        </>
      )}
      {open && (
        <UserDetails selectedUser={selectedUser} close={handleCloseDialog} />
      )}
    </>
  );
};

export default Verified;
