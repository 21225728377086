import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import Login from "./Component/Home/Login";
import NavBar from "./Component/Header/NavBar";
import Dashboard from "./Component/Home/Dashboard"; // Assuming Dashboard exists here
import ToVerify from "./Component/Home/Admin/ToVerify";
import Aboutus from "./Component/Details/Aboutus";
import Tnc from "./Component/Details/Tnc";
import Footer from "./Component/Header/Footer";
import Profile from "./Component/Home/User/Profile";
import Verified from "./Component/Home/Admin/Verified";
import Attachment from "./Component/Home/User/Attachment";
import Education from "./Component/Home/User/Education";
import Experience from "./Component/Home/User/Experience";
import Circular from "./Component/Details/Circular";
import Rejected from "./Component/Home/Admin/Rejected";
import Acr from "./Component/Home/User/Acr";
import MIS from "./Component/Home/Admin/MIS";

const App = () => {
  const userId = JSON.parse(localStorage.getItem("user"))?.usertypeId;
  console.log("User id is " + userId);

  return (
    <Router>
      {/* Navigation bar will be visible across all pages */}
      <NavBar />
      <div className="mt-16 px-4 min-h-screen">
        <Routes>
          <Route path="/aboutus" element={<Aboutus />} />
          <Route path="/tnc" element={<Tnc />} />
          <Route
            path="/"
            element={userId ? <Navigate to="/dashboard" replace /> : <Login />}
          />
          <Route path="/dashboard" element={userId && <Dashboard />} />
          <Route
            path="/admin/mis"
            element={
              userId === 2 || userId === 1 ? (
                <MIS />
              ) : (
                <Navigate to="/" replace />
              )
            }
          />
          <Route
            path="/admin/toverify"
            element={
              userId === 2 || userId === 1 ? (
                <ToVerify />
              ) : (
                <Navigate to="/" replace />
              )
            }
          />
          <Route
            path="/admin/verified"
            element={
              userId === 2 || userId === 1 ? (
                <Verified />
              ) : (
                <Navigate to="/" replace />
              )
            }
          />
          <Route
            path="/admin/rejected"
            element={
              userId === 2 || userId === 1 ? (
                <Rejected />
              ) : (
                <Navigate to="/" replace />
              )
            }
          />

          <Route path="/profile" element={userId && <Profile />} />
          <Route path="/profile_image" element={userId && <Attachment />} />
          <Route path="/pan" element={userId && <Attachment />} />
          <Route path="/aadhar" element={userId && <Attachment />} />
          <Route path="/caste" element={userId && <Attachment />} />
          <Route path="/education" element={userId && <Education />} />
          <Route path="/experience" element={userId && <Experience />} />
          <Route path="/circular" element={<Circular />} />
          <Route path="/fitness_certificate" element={userId && <Attachment />} />
          <Route path="/ppo" element={userId && <Attachment />} />
          <Route path="/last_five_year_acr" element={userId && <Attachment />} />
        </Routes>
      </div>
      <Footer />
    </Router>
  );
};

export default App;
