import React, { useRef, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Card,
  Typography,
  CircularProgress,
} from "@mui/material";
import { useReactToPrint } from "react-to-print";
import * as XLSX from "xlsx";
import UserDetails from "../User/UserDetails";
import { callerFunction } from "../../../Api";
const SearchList = ({ tutorsList, searchPara }) => {
  const contentRef = useRef(null);
  const [open, setOpen] = useState(null)
  const [loader, setLoader]=useState(false)
  const reactToprint = useReactToPrint({
    contentRef,
    documentTitle: "",
    pageStyle: `
      @media print {
        body {
          -webkit-print-color-adjust: exact;
          width: 820px;
        }
      }
    `,
  });

  const handleExport = () => {
    // Create a worksheet from the table data
    const data = tutorsList.map((tutor, index) => ({
      SN: index + 1,
      Name: tutor.name,
      "Father / Spouse Name": tutor.fsName,
      Gender: tutor.gender,
      Category: tutor.category,
      Subjects: tutor.subject,
      District: tutor.aDistrict,
      Score: tutor.score.toFixed(1),
      Mobile: tutor.mobile,
    }));

    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Applicant");
    XLSX.writeFile(workbook, "applicatlist.xlsx");
  };
  const handleOpenDialog = async (id) => {
    setLoader(true);
    const x = await callerFunction(`Action/applicantdetails?id=${id}`);
    setOpen(x);
    //setOpen(true);
    setLoader(false);
  };
  return (
    <>
      {open === null && !loader ?
        <div className="mt-4 " id="printable" ref={contentRef}>
          <div className="mb-2">
            <Card elevation={4} className=" px-16 py-4 items-center">
              <p className="text-center font-semibold py-2 text-xl">
                आवेदक (सेवानिर्वित शिक्षक) की सूची
              </p>
              <div className="flex justify-between items-center">
                <Typography>District: {searchPara?.selectedDist?.name ?? "All"}</Typography>
                <Typography>
                  Subject: {searchPara?.subject === "" ? "All" : searchPara?.subject}
                </Typography>
                <Typography>
                  Category: {searchPara?.categoryId === "" ? "All" : searchPara?.categoryId}
                </Typography>
                <Typography>
                  Date: {searchPara.fdate + " ," + searchPara?.tdate}
                </Typography>
              </div>
            </Card>
          </div>


          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  {[
                    "SN",
                    "Name",
                    "Father/Spouse Name",
                    "Gender",
                    "Category",
                    "Subjects",
                    "District",
                    "Score",
                    "Mobile",
                  ].map((header) => (
                    <TableCell
                      key={header}
                      sx={{
                        border: "1px solid black",
                        fontWeight: "bold",
                        textAlign: "center",
                      }}
                    >
                      {header}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {tutorsList.map((tutor, index) => (
                  <TableRow key={tutor.id} >
                    <TableCell sx={{ border: "1px solid black", textAlign: "center" }} onClick={() => handleOpenDialog(tutor.id)}>
                      <span className="hover:cursor-pointer" ><u>{index + 1}</u></span>
                    </TableCell>
                    <TableCell sx={{ border: "1px solid black", textAlign: "center" }}>
                      {tutor.name}
                    </TableCell>
                    <TableCell sx={{ border: "1px solid black", textAlign: "center" }}>
                      {tutor.fsName}
                    </TableCell>
                    <TableCell sx={{ border: "1px solid black", textAlign: "center" }}>
                      {tutor.gender}
                    </TableCell>
                    <TableCell sx={{ border: "1px solid black", textAlign: "center" }}>
                      {tutor.category}
                    </TableCell>
                    <TableCell sx={{ border: "1px solid black", textAlign: "center" }}>
                      {tutor.subject}
                    </TableCell>
                    <TableCell sx={{ border: "1px solid black", textAlign: "center" }}>
                      {tutor.aDistrict}
                    </TableCell>
                    <TableCell sx={{ border: "1px solid black", textAlign: "center" }}>
                      {tutor.score.toFixed(1)}
                    </TableCell>
                    <TableCell sx={{ border: "1px solid black", textAlign: "center" }}>
                      {tutor.mobile}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
        : <>
          <UserDetails selectedUser={open} close={() => setOpen(null)} />
        </>
      }

      <>
        {open === null && !loader &&
          <div className="flex justify-end items-center mt-4 gap-4 mb-2">
            <Button onClick={handleExport} variant="contained" color="secondary">
              Export
            </Button>
            <Button onClick={() => reactToprint()} variant="contained" color="primary" className="ml-2">
              Print
            </Button>
          </div>
        }
      </>
      {
        loader && 
        <div className="flex justify-center items-center w-full h-screen">
          <CircularProgress size="4rem" />
        </div>
      }
    </>
  );
};

export default SearchList;
