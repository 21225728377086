import axios from "axios";
import React, { useEffect, useState } from "react";

import { fetchData } from "../../Api";
import Swal from "sweetalert2";
import {
  Typography,
  Card,
  CardContent,
  Grid,
  CircularProgress,
  Badge,
  Divider,
  Paper,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Box,
  IconButton,
} from "@mui/material";
import { IoCheckmarkDoneCircleSharp } from "react-icons/io5";
import { MdCancel, MdNotificationsActive, MdArrowForward } from "react-icons/md";
import { json, Link } from "react-router-dom";
import AdminDashboard from "./AdminDashboard";
import { callerFunction } from "../../Api";

const Dashboard = () => {
  const [notification, setNotification] = useState([]);
  const [profileHealth, setProfileHealth] = useState([]);
  const [admDashData, setAdmDashData] = useState([]);
  const [loader, setLoader] = useState(false);

  const userId = JSON.parse(localStorage.getItem("user"))?.usertypeId;
  const token = JSON.parse(localStorage.getItem("user"))?.password;
  console.log('Token ', token);
  console.log(userId === 1);
  useEffect(() => {
    if (userId === 3) {
      getDashboardData();
    } else {
      getAdmin1DashboardData();
    }
  }, []);

  const getAdmin1DashboardData = async () => {
    setLoader(true);
    const data = await callerFunction("Action/dashboard");
    setAdmDashData(data);
    setLoader(false);
  };

  const getDashboardData = async () => {
    setLoader(true);
    try {
      const res = await fetchData(`Activity/userdashboard`);
      setProfileHealth(res?.profilehealths);
      setNotification(res.notification);
      setLoader(false);
    } catch (e) {
      Swal.fire({ title: "Error", icon: "error", text: e.details });
      console.error(e);
      setLoader(false);
    }
  };

  function formatDate(dateString) {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-based, so add 1
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  }

  return (
    <>
      {loader ? (
        <div className="flex justify-center items-center w-full h-screen">
          <CircularProgress size="4rem" />
        </div>
      ) : (
        <div className="mt-20 mb-4">
          <>
            {userId === 3 && (
              <Box className="flex flex-col md:flex-row gap-6">
                {/* Profile Health Section */}
                <Paper
                  elevation={3}
                  className="p-4 flex-1"
                  style={{ borderRadius: "12px" }}
                >
                  <Typography
                    variant="h5"
                    className="mb-4 text-center"
                    style={{ fontWeight: 700, color: "#0d6efd" }}
                  >
                    Profile Health
                  </Typography>
                  <Divider className="mb-4" />
                  <Grid container spacing={2}>
                    {profileHealth.map((item, index) => (
                      <Grid item xs={12} sm={6} key={index}>
                        <Card
                          elevation={4}
                          className="bg-white hover:shadow-md transition-shadow duration-200"
                          style={{
                            padding: "10px",
                            borderRadius: "10px",
                          }}
                        >
                          <CardContent className="flex items-center justify-between">
                            <Link
                              to={`/${item.name.toLowerCase().replace(/\s+/g, "_")}`}
                              className="text-decoration-none"
                              style={{
                                fontSize: "18px",
                                fontWeight: "500",
                                color: "#333",
                              }}
                            >
                              {item.name}
                            </Link>
                            {item.status ? (
                              <IoCheckmarkDoneCircleSharp fontSize={30} color="#198754" />
                            ) : (
                              <MdCancel fontSize={30} color="red" />
                            )}
                          </CardContent>
                        </Card>
                        
                      </Grid>
                    ))}
                  </Grid>
                </Paper>

                {/* Notifications Section */}
                <Paper
                  elevation={3}
                  className="p-4 flex-1"
                  style={{ borderRadius: "12px" }}
                >
                  <div className="d-flex justify-content-between align-items-center mb-4">
                    <Typography
                      variant="h5"
                      style={{ fontWeight: 700, color: "#ffc107" }}
                    >
                      Notifications
                    </Typography>
                    <Badge
                      className="mt-4"
                      badgeContent={notification.length}
                      color="success"
                    >
                     
                    </Badge>
                  </div>
                  <Divider className="mb-3" />
                  <List style={{ maxHeight: "300px", overflowY: "auto" }}>
                    {notification.length > 0 ? (
                      notification.map((item, index) => (
                        <ListItem
                          key={index}
                          className="mb-2"
                          style={{ borderBottom: "1px solid #eee" }}
                        >
                          

                          <ListItemText primary={formatDate(item.ondate)} />
                          <ListItemText primary={item.narration} />
                        </ListItem>
                      ))
                    ) : (
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        className="text-center"
                      >
                        No new notifications.
                      </Typography>
                    )}
                  </List>
                </Paper>
              </Box>
            )}
            {userId === 1 && <AdminDashboard dashboardData={admDashData} />}
            {userId === 2 && <AdminDashboard dashboardData={admDashData} />}
          </>
        </div>
      )}
    </>
  );
};

export default Dashboard;
