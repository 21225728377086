import React, { useEffect, useState } from 'react'
import {
  Button,
  TextField,
  Card,
  CardContent,
  Grid,
  Dialog,
  TableRow,
  TableCell,
  Table,
  Typography,
  CircularProgress,
} from '@mui/material'
import { FaEye } from 'react-icons/fa'
import Swal from 'sweetalert2'
import { delteData, postData } from '../../../Api'
import { callerFunction } from '../../../Api'
import axios from 'axios'

const defaultEducationList = {
  id: '0',
      memberId: 0,
      course: '',
      institute: '',
      passing: '',
      percentage: '',
      attachment: null,
      attachmentPreview: '',
}


const Education = () => {
  
  const [preview, setPreview] = useState({ open: false, content: '' })
  const [educationData, setEducationData] = useState([])
  const [loading, setLoader] = useState(false)
  const [educationList, setEducationList] = useState([
    defaultEducationList
  ])

  const loader = (v)=>{
    setLoader(v);
  }

  useEffect(() => {
    getEducationList()
  }, [])

  const getEducationList = async () => {
    loader(true)
    const data = await callerFunction('Activity/myeducationList')
    setEducationData(data)
    loader(false)
  }

  const handleInputChange = (index, event) => {
    const { name, value } = event.target
    const newEducationList = [...educationList]
    newEducationList[index][name] = value
    setEducationList(newEducationList)
  }

  const handleFileChange = (index, event) => {
    const file = event.target.files[0]
    if (file) {
      const newEducationList = [...educationList]
      newEducationList[index].attachment = file
      const reader = new FileReader()
      reader.onloadend = () => {
        newEducationList[index].attachment = reader.result
        newEducationList[index].attachmentPreview = URL.createObjectURL(file)
        setEducationList(newEducationList)
      }
      reader.readAsDataURL(file)

      if (file.type.startsWith('image/')) {
        newEducationList[index].attachmentPreview = URL.createObjectURL(file)
      } else if (file.type === 'application/pdf') {
        newEducationList[index].attachmentPreview = URL.createObjectURL(file)
      }
      setEducationList(newEducationList)
    }
  }

  const handlePreview = (content) => {
    setPreview({ open: true, content })
  }

  const handleClosePreview = () => {
    setPreview({ open: false, content: '' })
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    loader(true)
    const submitData = educationList.map(({ attachmentPreview, ...rest }) => rest)
    try {
      const res = await postData('Activity/neweducaton', submitData)
      setEducationList([defaultEducationList])
      getEducationList();
 
      Swal.fire({
        title: 'Success',
        icon: 'success',
        text: res,
      })
    } catch (e) {
      console.error(e)
      Swal.fire({
        title: 'Error',
        icon: 'error',
        text: e.details,
      })
    }
    loader(false)
  }

  const isFilePreviewable = (attachment) => {
    return (
      attachment &&
      attachment.type &&
      (attachment.type.startsWith('image/') || attachment.type === 'application/pdf')
    )
  }

  return (
    <>
    {loading ? (
        <div className="flex justify-center items-center w-full h-screen">
        <CircularProgress size="4rem" />
      </div>
    ) : (
        <>
      <div className="container mt-4">
        <p className="text-center mb-4 text-xl font-semibold">Education Details</p>
        
        {educationList.map((education, index) => (
          <Card key={index} className="mb-3">
            <CardContent>
              <form onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Course"
                    name="course"
                    variant="outlined"
                    required
                    value={education.course}
                    onChange={(e) => handleInputChange(index, e)}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Institute"
                    name="institute"
                    variant="outlined"
                    required
                    value={education.institute}
                    onChange={(e) => handleInputChange(index, e)}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Passing Year"
                    name="passing"
                    required
                    variant="outlined"
                    value={education.passing}
                    onChange={(e) => handleInputChange(index, e)}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Percentage / Grade"
                    name="percentage"
                    variant="outlined"
                    required
                    value={education.percentage}
                    onChange={(e) => handleInputChange(index, e)}
                  />
                </Grid>
                <Grid item xs={12} className="d-flex justify-content-center px-4">
                  <label htmlFor={`file-upload-${index}`}>
                    <input
                      type="file"
                      id={`file-upload-${index}`}
                      style={{ display: 'none' }}
                      onChange={(e) => handleFileChange(index, e)}
                    />
                    <Button variant="outlined" color="primary" component="span" fullWidth>
                      Upload Attachment
                    </Button>
                  </label>
                  {education.attachment && isFilePreviewable(education.attachment) && (
                    <FaEye
                      style={{
                        marginLeft: 8,
                        fontSize: 22,
                        cursor: 'pointer',
                      }}
                      onClick={() => handlePreview(education.attachmentPreview)}
                    />
                  )}
                </Grid>
              </Grid>
                  <div className='flex justify-center items-baseline mt-4'>
                    <button className="bg-[#002b47] rounded-md p-2 px-4 hover:cursor-pointer text-white  hover:shadow-md hover:shadow-black">Submit</button>
                  </div>
              </form>
            </CardContent>
          </Card>
        ))}
        <div className="d-flex justify-content-center">
          {/* <Button variant="outlined" onClick={handleAddMore} disabled={educationList.length >= 10}>
            Add More
          </Button> */}
          
        </div>
        <div className="container mt-4">
          <div className="grid md:grid-cols-3 grid-cols-3 gap-3">
            {educationData.map((item, index) => (
              <div key={index} className="col-md-6 col-12 mb-4">
                <Card>
                  <CardContent>
                    <Grid container spacing={2}>
                      <Grid item xs={12} className="d-flex justify-content-between px-4 ">
                        <strong>Course :</strong>{item.course}
                        
                      </Grid>
                      <Grid item xs={12} className="d-flex justify-content-between px-4">
                        <strong>Institute:</strong> {item.institute}
                      </Grid>
                      <Grid item xs={12} className="d-flex justify-content-between px-4">
                        <strong>Passing Year:</strong> {item.passing}
                      </Grid>
                      <Grid item xs={12} className="d-flex justify-content-between px-4">
                        <strong>Percentage/Grade :</strong> {item.percentage}
                      </Grid>
                      <Grid item xs={12} className="d-flex justify-content-between px-4">
                        <strong>Attachment:</strong>{' '}
                        {item.attachment ? (
                          <a
                            href={`https://scst.bprnetwork.in/${item.attachment}`}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            View Document
                          </a>
                        ) : (
                          'No Attachment'
                        )}
                      </Grid>
                    </Grid>
                    <div className="flex justify-center items-center mt-4 h-8 overflow-hidden">
                    <Button
  onClick={() => {
    Swal.fire({
      title: 'Are you sure?',
      text: 'Do you want to Delete?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, Delete',
      cancelButtonText: 'No, Cancel',
      confirmButtonColor: 'red',
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          
          const response = await delteData(`Activity/deleteeducation?id=${item.id}`); 
          
          if (response) { // check if delete was successful
            Swal.fire({
              icon: 'success',
              title: 'Deleted!',
              text: response,
            });
            getEducationList();
          }
        } catch (error) {
          Swal.fire({
            icon: 'error',
            title: 'Error!',
            text: error.details,
          });
        }
      }
    });
  }}
  variant="contained"
  color="error"
>
  Delete
</Button>

                    </div>
                  </CardContent>
                </Card>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="mt-4 mb-2 flex justify-center items-center flex-col">
            <Card
              elevation={2}
              className="px-16 p-6 flex flex-col justify-center items-center"
            >
              <Typography fontWeight={600} fontSize={18}>
                Complete
              </Typography>
              <div className="flex justify-center items-center gap-8 mt-2">
                <button onClick={()=>window.location.href='/pan'} className="bg-[#002b47] p-2 px-4 hover:cursor-pointer text-white rounded-sm hover:shadow-md hover:shadow-black">
                  Documents
                </button>
                <button onClick={()=>window.location.href='/experience'} className="bg-green-700 p-2 px-4 hover:cursor-pointer text-white rounded-sm hover:shadow-md hover:shadow-black">
                  Experience
                </button>
              </div>
            </Card>
          </div>

      <Dialog open={preview.open} onClose={handleClosePreview}>
        <div style={{ padding: '20px' }}>
          {preview.content && preview.content.endsWith('.pdf') ? (
            <embed src={preview.content} type="application/pdf" width="100%" height="500px" />
          ) : (
            <img
              src={preview.content}
              alt="Attachment Preview"
              style={{ width: '100%', maxHeight: '500px', objectFit: 'contain' }}
            />
          )}
        </div>
      </Dialog>
      </>
)}
    </>
  )
}

export default Education
