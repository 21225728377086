import React, { useEffect, useState } from "react";

import {
  Autocomplete,
  Button,
  Card,
  CardMedia,
  Checkbox,
  CircularProgress,
  Container,
  TextField,
  Typography,
} from "@mui/material";
import { fetchData, postData } from "../../../Api";
import {
  getCategories,
  getDistrict,
  getGenders,
  getMyProfile,
  getSubjects,
} from "../../../Functon/function";
import Swal from "sweetalert2";
import Acr from "./Acr";
import {
  MdDetails,
  MdLocationOn,
  MdOutlineAssessment,
  MdPerson2,
  MdPrivateConnectivity,
  MdWorkHistory,
  MdWorkspacesFilled,
} from "react-icons/md";

const defaultFormData = {
  name: "",
  fsName: "",
  dob: "1990-01-01",
  doj: "1990-01-01",
  firstSchoolName: "",
  lastSchoolName: "",
  dor: "1990-01-01",
  lastSalary: "",
  subject: "",
  mobile: "",
  email: "",
  panNo: "",
  aadharNo: "",
  pAddress1: "",
  pAddress2: "",
  pBlock: "",
  pDistrictId: "",
  pPincode: "",
  cAddress2: "",
  cBlock: "",
  cDistrictId: "",
  cAddress1: "",
  cPincode: "",
  entrydate: "1990-01-01",
  entryBy: 0,
  member: 0,
  panAttachment: "",
  aadharAttachment: "",
  profileImage: "",
  gender: "",
  category: "",
  categoryAttachment: "",
  aDistrictId: "",
  applyPost: "",
  retiredPost: "",
  retiredDept: "",
  fixedPension: "",
  gettingPension: "",
  grade: Array(9).fill(null),
};

const Profile = () => {
  const [personalData, setPersonalData] = useState(defaultFormData);

  const [checked, setChecked] = useState(false);
  const [districts, setDistricts] = useState([]);
  const [gender, setGender] = useState([]);
  const [category, setCategory] = useState([]);
  const [subjects, setsubject] = useState([]);
  const [loading, setLoader] = useState(false);
  const [gradepara, setGradpara] = useState([]);

  const handleChange = (e) => {
    setPersonalData({ ...personalData, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    getmasterData();
    getgradpara();
  }, []);

 

  const loader = (v) => {
    setLoader(v);
  };

  const getmasterData = async () => {
    loader(true);
    const profileData = await getMyProfile();
    if (profileData) {
      setPersonalData(profileData);
    }

    loader(false);
    const dres = await getDistrict();
    const gres = await getGenders();
    const sres = await getSubjects();
    const cres = await getCategories();
    setDistricts(dres);
    setGender(gres);
    setCategory(cres);
    setsubject(sres);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    loader(true);
    try {
      const res = await postData("Activity/newprofile", personalData);
      if (res) {
        Swal.fire({
          icon: "success",
          title: "Success",
          text: res,
        }).then(() => {
          window.location.href = "/pan";
        });
      }
    } catch (e) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: e.details,
      });
    }
    loader(false);
  };

  const getgradpara = async () => {
    setLoader(true);
    try {
      const res = await fetchData("Master/gradepara");
      setGradpara(res);
      setLoader(false);
    } catch (e) {
      console.error(e);
      setLoader(false);
    }
  };

  return (
    <>
      {loading ? (
        <div className="flex justify-center items-center w-full h-screen">
          <CircularProgress size="4rem" />
        </div>
      ) : (
        <div className="items-center p-2">
          <Typography className="text-center " fontWeight={700} fontSize={32}>
            Your Profile
          </Typography>
          <h1 className="text-2xl text-center w-full font-bold flex items-center mb-4 text-gray-800">
            <span className="flex items-center cursor-help mt-8">
              <MdPerson2 className="text-green-600 mr-2" />
              Personal Details
            </span>
          </h1>
          {gradepara && (
            <form onSubmit={handleSubmit}>
              <div className="grid md:grid-cols-3 grid-cols-1">
                <Container maxWidth={"xs"} className="mt-4">
                  <TextField
                    fullWidth
                    label="Name as on Service Book"
                    name="name"
                    variant="outlined"
                    color="success"
                    type="name"
                    value={personalData.name}
                    onChange={(e) => handleChange(e)}
                    required
                  />
                </Container>
                <Container maxWidth={"xs"} className="mt-2">
                  <TextField
                    label="Father's / Spouse Name "
                    name="fsName"
                    variant="outlined"
                    color="success"
                    type="text"
                    value={personalData.fsName}
                    onChange={(e) => handleChange(e)}
                    required
                    fullWidth
                  />
                </Container>
                <Container maxWidth={"xs"} className="mt-4">
                  <Autocomplete
                    options={gender.map((item) => item)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        fullWidth
                        label="Gender"
                        name="gender"
                        variant="outlined"
                        // color="success"
                        type="text"
                        required
                      />
                    )}
                    onInputChange={(event, newValue) => {
                      setPersonalData({
                        ...personalData,
                        gender: newValue,
                      });
                    }}
                    value={
                      gender.find((item) => item === personalData.gender)
                        ? personalData.gender
                        : null
                    }
                  />
                </Container>

                <Container maxWidth={"xs"} className="mt-4">
                  <Autocomplete
                    options={category?.map((item) => item.cname)}
                    freeSolo={false}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Category"
                        name="category"
                        variant="outlined"
                        color="success"
                        type="text"
                        required
                        fullWidth
                      />
                    )}
                    onInputChange={(event, newValue) => {
                      setPersonalData({
                        ...personalData,
                        category: newValue ? newValue : "",
                      });
                    }}
                    value={
                      category.find(
                        (item) => item.cname == personalData.category
                      )
                        ? personalData.category
                        : null
                    }
                  />
                </Container>
                <Container maxWidth={"xs"} className="mt-4">
                  <TextField
                    label="Date of Birth"
                    name="dob"
                    variant="outlined"
                    color="success"
                    type="date"
                    value={personalData.dob}
                    onChange={(e) => handleChange(e)}
                    required
                    fullWidth
                  />
                </Container>

                <Container maxWidth={"xs"} className="mt-4">
                  <TextField
                    label="Date of Joining"
                    name="doj"
                    variant="outlined"
                    color="success"
                    type="date"
                    value={personalData.doj}
                    onChange={(e) => handleChange(e)}
                    required
                    fullWidth
                  />
                </Container>
                {/* <Container maxWidth={'xs'} className="mt-4">
              <TextField
                label="Date of Entry"
                name="entrydate"
                variant="outlined"
                color="success"
                type="date"
                value={personalData.entrydate}
                onChange={(e) => handleChange(e)}
                required
                fullWidth
              />
            </Container> */}

                <Container maxWidth={"xs"} className="mt-4">
                  <TextField
                    label="First Joined School Name"
                    name="firstSchoolName"
                    variant="outlined"
                    color="success"
                    type="text"
                    value={personalData.firstSchoolName}
                    onChange={(e) => handleChange(e)}
                    required
                    fullWidth
                  />
                </Container>
                <Container maxWidth={"xs"} className="mt-4">
                  <TextField
                    label="Last Joined School Name"
                    name="lastSchoolName"
                    variant="outlined"
                    color="success"
                    type="text"
                    value={personalData.lastSchoolName}
                    onChange={(e) => handleChange(e)}
                    required
                    fullWidth
                  />
                </Container>
                <Container maxWidth={"xs"} className="mt-4">
                  <TextField
                    label="Date of Retirenment"
                    name="dor"
                    variant="outlined"
                    color="success"
                    type="date"
                    value={personalData.dor}
                    onChange={(e) => handleChange(e)}
                    required
                    fullWidth
                  />
                </Container>
                <Container maxWidth={"xs"} className="mt-4">
                  <TextField
                    label="Last Salary (In Hand)"
                    name="lastSalary"
                    variant="outlined"
                    color="success"
                    type="text"
                    value={personalData.lastSalary}
                    onChange={(e) => handleChange(e)}
                    required
                    fullWidth
                  />
                </Container>
                <Container maxWidth={"xs"} className="mt-4">
                  <Autocomplete
                    multiple
                    options={subjects.map((item) => item.sname)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Subject Specialization"
                        name="subject"
                        variant="outlined"
                        color="success"
                        type="text"
                        fullWidth
                      />
                    )}
                    onChange={(event, newValue) => {
                      // Join selected values into a single string separated by commas
                      const selectedSubjects = newValue.join(", ");
                      setPersonalData({
                        ...personalData,
                        subject: selectedSubjects,
                      });
                    }}
                    value={
                      personalData.subject
                        ? personalData.subject.split(", ")
                        : []
                    }
                  />
                </Container>

                <Container maxWidth={"xs"} className="mt-4">
                  <TextField
                    label="Mobile ( +91 )"
                    name="mobile"
                    variant="outlined"
                    color="success"
                    type="number"
                    value={personalData.mobile}
                    onChange={(e) => handleChange(e)}
                    inputProps={{ maxLength: 10 }}
                    onInput={(e) => {
                      if (e.target.value.length > 10) {
                        e.target.value = e.target.value.slice(0, 10);
                      }
                    }}
                    required
                    fullWidth
                  />
                </Container>
                <Container maxWidth={"xs"} className="mt-4">
                  <TextField
                    label="Email"
                    name="email"
                    variant="outlined"
                    color="success"
                    type="email"
                    value={personalData.email}
                    onChange={(e) => handleChange(e)}
                    required
                    fullWidth
                  />
                </Container>

                <Container maxWidth={"xs"} className="mt-4">
                  <TextField
                    label="AADHAR No."
                    name="aadharNo"
                    variant="outlined"
                    color="success"
                    type="number"
                    inputProps={{ maxLength: 12 }}
                    onInput={(e) => {
                      if (e.target.value.length > 10) {
                        e.target.value = e.target.value.slice(0, 12);
                      }
                    }}
                    value={personalData.aadharNo}
                    onChange={(e) => handleChange(e)}
                    required
                    fullWidth
                  />
                </Container>
              </div>

              <div className="flex justify-start items-start w-full mt-8">
                <h1 className="text-2xl text-center w-full font-bold flex items-center mb-4 text-gray-800">
                  <span className="flex items-center cursor-help mt-8">
                    <MdLocationOn className="text-green-600 mr-2" />
                    Address
                  </span>
                </h1>
              </div>
              <div className="grid md:grid-cols-3 grid-cols-1">
                <Container maxWidth={"xs"} className="mt-2">
                  <TextField
                    label="Address 1"
                    name="pAddress1"
                    variant="outlined"
                    color="success"
                    type="text"
                    value={personalData.pAddress1}
                    onChange={(e) => handleChange(e)}
                    required
                    fullWidth
                  />
                </Container>
                <Container maxWidth={"xs"} className="mt-2">
                  <TextField
                    label="Address 2"
                    name="pAddress2"
                    variant="outlined"
                    color="success"
                    type="text"
                    value={personalData.pAddress2}
                    onChange={(e) => handleChange(e)}
                    required
                    fullWidth
                  />
                </Container>
                <Container maxWidth={"xs"} className="mt-4">
                  <TextField
                    label="Block"
                    name="pBlock"
                    variant="outlined"
                    color="success"
                    type="text"
                    value={personalData.pBlock}
                    onChange={(e) => handleChange(e)}
                    required
                    fullWidth
                  />
                </Container>
                <Container maxWidth={"xs"} className="mt-4">
                  <Autocomplete
                    options={districts.map((item) => item.name)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="District"
                        name="pDistrictId"
                        variant="outlined"
                        color="success"
                        type="text"
                        required
                        fullWidth
                      />
                    )}
                    onChange={(event, newValue) => {
                      const district = districts.find(
                        (d) => d.name === newValue
                      );
                      if (district) {
                        const id = district.id;
                        setPersonalData({ ...personalData, pDistrictId: id });
                      }
                    }}
                    value={
                      districts.find((d) => d.id === personalData.pDistrictId)
                        ?.name || ""
                    }
                  />
                </Container>

                <Container maxWidth={"xs"} className="mt-4">
                  <TextField
                    label="Area Pin Code / ZIP Code"
                    name="pPincode"
                    variant="outlined"
                    color="success"
                    type="number"
                    value={personalData.pPincode}
                    onChange={(e) => handleChange(e)}
                    required
                    fullWidth
                  />
                </Container>
              </div>
              <h1 className="text-2xl text-center w-full font-bold flex items-center mb-4 text-gray-800">
                <span className="flex items-center cursor-help mt-8">
                  <MdWorkspacesFilled className="text-green-600 mr-2" />
                  Job Details
                </span>
              </h1>
              <div className="grid md:grid-cols-3 grid-cols-1">
                <Container maxWidth={"xs"} className="mt-4">
                  <Autocomplete
                    options={districts.map((item) => item.name)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="District (Applied For )"
                        name="aDistrictId"
                        variant="outlined"
                        color="success"
                        type="text"
                        required
                        fullWidth
                      />
                    )}
                    onInputChange={(event, newValue) => {
                      const district = districts.find(
                        (d) => d.name === newValue
                      );
                      if (district) {
                        const id = district.id;
                        setPersonalData({ ...personalData, aDistrictId: id });
                      }
                    }}
                    value={
                      districts.find((d) => d.id === personalData.aDistrictId)
                        ?.name || ""
                    }
                  />
                </Container>
                <Container maxWidth={"xs"} className="mt-4">
                  <Autocomplete
                    options={gradepara?.apply_post?.map((item) => item?.name)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Job Post ( Applied For )"
                        name="applyPost"
                        variant="outlined"
                        color="success"
                        type="text"
                        required
                        fullWidth
                      />
                    )}
                    onInputChange={(event, newValue) => {
                      const post = gradepara.apply_post.find(
                        (d) => d.name === newValue
                      );
                      if (post) {
                        const id = post.id;
                        setPersonalData({ ...personalData, applyPost: id });
                      }
                    }}
                    value={
                      gradepara?.apply_post?.find(
                        (d) => d.id === personalData.applyPost
                      )?.name || ""
                    }
                  />
                </Container>
                <Container maxWidth={"xs"} className="mt-4">
                  <Autocomplete
                    options={gradepara?.retired_post?.map((item) => item.name)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Retired from ( Post )"
                        name="retiredPost"
                        variant="outlined"
                        color="success"
                        type="text"
                        required
                        fullWidth
                      />
                    )}
                    onInputChange={(event, newValue) => {
                      const retPost = gradepara.retired_post.find(
                        (d) => d.name === newValue
                      );
                      if (retPost) {
                        const id = retPost.id;
                        setPersonalData({ ...personalData, retiredPost: id });
                      }
                    }}
                    value={
                      gradepara?.retired_post?.find(
                        (d) => d.id === personalData.retiredPost
                      )?.name || ""
                    }
                  />
                </Container>
                <Container maxWidth={"xs"} className="mt-4">
                  <Autocomplete
                    options={gradepara?.retired_dept?.map((item) => item.name)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Retired  from ( Department )"
                        name="retiredDept"
                        variant="outlined"
                        color="success"
                        type="text"
                        required
                        fullWidth
                      />
                    )}
                    onInputChange={(event, newValue) => {
                      const retDept = gradepara.retired_dept.find(
                        (d) => d.name === newValue
                      );
                      if (retDept) {
                        const id = retDept.id;
                        setPersonalData({ ...personalData, retiredDept: id });
                      }
                    }}
                    value={
                      gradepara?.retired_dept?.find(
                        (d) => d.id === personalData.retiredDept
                      )?.name || ""
                    }
                  />
                </Container>
                <Container maxWidth={"xs"} className="mt-4">
                  <Autocomplete
                    options={gradepara?.age_at_contract?.map((item) => ({
                      label: item.name,
                      value: item.id,
                    }))}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Age at Contract"
                        name="ageAtContract"
                        variant="outlined"
                        color="success"
                        type="text"
                        required
                        fullWidth
                      />
                    )}
                    onInputChange={(event, newValue) => {
                      const selectedOption = gradepara?.age_at_contract.find(
                        (item) => item.name === newValue
                      );

                      if (selectedOption) {
                        const newId = selectedOption.id;

                        setPersonalData((prevData) => {
                          const updatedGrade = Array.isArray(prevData.grade)
                            ? [...prevData.grade]
                            : Array(9).fill(null);

                          updatedGrade[0] = newId;

                          return {
                            ...prevData,
                            grade: updatedGrade,
                          };
                        });
                      }
                    }}
                    value={gradepara?.age_at_contract?.find(
                      (item) => item.id === personalData.grade?.[0] // Match the selected `id` with the 1st index of `grade`
                    )}
                    getOptionLabel={(option) => option?.label || ""} // Show the `name` label in the dropdown
                    isOptionEqualToValue={(option, value) =>
                      option.value === value?.id
                    } // Ensure correct value matching
                  />
                </Container>

                <Container maxWidth={"xs"} className="mt-4">
                  <TextField
                    label="Fixed Pension"
                    name="fixedPension"
                    variant="outlined"
                    color="success"
                    type="number"
                    value={personalData.fixedPension}
                    onChange={(e) => handleChange(e)}
                    required
                    fullWidth
                  />
                </Container>
                <Container maxWidth={"xs"} className="mt-4">
                  <TextField
                    label="Current Pension"
                    name="gettingPension"
                    variant="outlined"
                    color="success"
                    type="number"
                    value={personalData.gettingPension}
                    onChange={(e) => handleChange(e)}
                    required
                    fullWidth
                  />
                </Container>
              </div>
              {personalData.grade && 
              <Acr
                gradepara={gradepara}
                setPersonalData={setPersonalData}
                personalData={personalData}
              />
            }
              <h1 className="text-2xl text-center w-full font-bold flex items-center mb-4 text-gray-800">
                <span className="flex items-center cursor-help mt-8">
                  <MdWorkHistory className="text-green-600 mr-2" />
                  Experience Details
                </span>
              </h1>
              <div className="grid md:grid-cols-3 grid-cols-1">
                <Container maxWidth={"xs"} className="mt-4">
                  <Autocomplete
                    options={gradepara?.w_exp_oncern_dept?.map((item) => ({
                      label: item.name,
                      value: item.id,
                    }))}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Work Experience Concern Department"
                        name="wecd"
                        variant="outlined"
                        color="success"
                        type="text"
                        required
                        fullWidth
                      />
                    )}
                    onInputChange={(event, newValue) => {
                      const selectedOption = gradepara?.w_exp_oncern_dept.find(
                        (item) => item.name === newValue
                      );

                      if (selectedOption) {
                        const newId = selectedOption.id;

                        setPersonalData((prevData) => {
                          // Ensure `grade` is initialized as an array with a length of 9
                          const updatedGrade = Array.isArray(prevData.grade)
                            ? [...prevData.grade]
                            : Array(9).fill(null);

                          // Update the index that corresponds to this container, here index 1 (second container)
                          updatedGrade[1] = newId; // Update the second index (1) with the selected ID

                          return {
                            ...prevData,
                            grade: updatedGrade,
                          };
                        });
                      }
                    }}
                    value={gradepara?.w_exp_oncern_dept?.find(
                      (item) => item.id === personalData.grade?.[1] // Match the selected `id` with the 2nd index of `grade`
                    )}
                    getOptionLabel={(option) => option?.label || ""} // Show the `name` label in the dropdown
                    isOptionEqualToValue={(option, value) =>
                      option.value === value?.id
                    } // Ensure correct value matching
                  />
                </Container>

                <Container maxWidth={"xs"} className="mt-4">
                  <Autocomplete
                    options={gradepara?.w_exp_oth_dept?.map((item) => ({
                      label: item.name,
                      value: item.id,
                    }))}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Work Experience (Other Department)"
                        name="wExpOtherDept"
                        variant="outlined"
                        color="success"
                        type="text"
                        required
                        fullWidth
                      />
                    )}
                    onInputChange={(event, newValue) => {
                      const selectedOption = gradepara?.w_exp_oth_dept.find(
                        (item) => item.name === newValue
                      );

                      if (selectedOption) {
                        const newId = selectedOption.id;

                        setPersonalData((prevData) => {
                          // Ensure `grade` is initialized as an array with a length of 9
                          const updatedGrade = Array.isArray(prevData.grade)
                            ? [...prevData.grade]
                            : Array(9).fill(null);

                          // Update the third index (2) with the selected ID
                          updatedGrade[2] = newId;

                          return {
                            ...prevData,
                            grade: updatedGrade,
                          };
                        });
                      }
                    }}
                    value={gradepara?.w_exp_oth_dept?.find(
                      (item) => item.id === personalData.grade?.[2] // Match the selected `id` with the 3rd index of `grade`
                    )}
                    getOptionLabel={(option) => option?.label || ""} // Show the `name` label in the dropdown
                    isOptionEqualToValue={(option, value) =>
                      option.value === value?.id
                    } // Ensure correct value matching
                  />
                </Container>

                <Container maxWidth={"xs"} className="mt-4">
                  <Autocomplete
                    options={gradepara?.w_exp_same_dept_after_retire?.map(
                      (item) => ({
                        label: item.name,
                        value: item.id,
                      })
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Experience in Same Department (After Retirement)"
                        name="wExpSameDeptAfterRetire"
                        variant="outlined"
                        color="success"
                        type="text"
                        required
                        fullWidth
                      />
                    )}
                    onInputChange={(event, newValue) => {
                      const selectedOption =
                        gradepara?.w_exp_same_dept_after_retire.find(
                          (item) => item.name === newValue
                        );

                      if (selectedOption) {
                        const newId = selectedOption.id;

                        setPersonalData((prevData) => {
                          // Ensure `grade` is initialized as an array with a length of 9
                          const updatedGrade = Array.isArray(prevData.grade)
                            ? [...prevData.grade]
                            : Array(9).fill(null);

                          // Update the fourth index (3) with the selected ID
                          updatedGrade[3] = newId;

                          return {
                            ...prevData,
                            grade: updatedGrade,
                          };
                        });
                      }
                    }}
                    value={gradepara?.w_exp_same_dept_after_retire?.find(
                      (item) => item.id === personalData.grade?.[3] // Match the selected `id` with the 4th index of `grade`
                    )}
                    getOptionLabel={(option) => option?.label || ""} // Show the `name` label in the dropdown
                    isOptionEqualToValue={(option, value) =>
                      option.value === value?.id
                    } // Ensure correct value matching
                  />
                </Container>
              </div>

              <div className="flex mt-8 justify-center items-center w-full">
                {personalData.pAddress1.length<=10 ? 
              <p className="px-4 p-1 bg-gray-300 font-semibold text-red-600">Address 1 length is too short</p>  
              :
                <Button
                  disabled={personalData.isapproved}
                  variant="contained"
                  type="submit"
                 
                >
                  Submit
                </Button>
  }
              </div>
            </form>
          )}
        </div>
      )}
    </>
  );
};

export default Profile;
